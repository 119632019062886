/* src/app/sign-up/sign-up.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 21.5rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.sign-up {
  height: 100%;
  border-top: 3px solid #ffb500;
}
.sign-up .header {
  padding-top: 1em;
}
@media screen and (min-width: 48rem) {
  .sign-up .header {
    padding: 1em 8em;
  }
}
.sign-up .header .ups-logo img {
  height: auto;
  width: 12em !important;
}
@media screen and (min-width: 48rem) {
  .sign-up .header .ups-logo img {
    width: 17.8em;
  }
}
.sign-up .header .header-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
@media screen and (min-width: 48rem) {
  .sign-up .header .header-items {
    flex-direction: row;
    align-items: stretch;
  }
}
.sign-up .header .header-items .header-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  font-size: 13px;
}
.sign-up .header .header-items .header-item :first-child {
  margin-bottom: 0.3em;
}
@media screen and (min-width: 48rem) {
  .sign-up .header .header-items .header-item :first-child {
    margin-bottom: 0em;
  }
}
.sign-up .header .header-items .header-item .login {
  border-radius: 19.5px;
  height: 39px;
  width: 150px;
}
.sign-up .header .header-items .header-item label {
  margin-right: 2em;
  font-weight: 600;
}
.sign-up .sign-up-wrapper {
  background-color: #f6f6f6;
}
@media screen and (min-width: 48rem) {
  .sign-up .sign-up-wrapper {
    padding: 0em 8em;
  }
}
.sign-up .sign-up-wrapper .account-registration {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #595555;
  padding: 0.8em 1em;
}
@media screen and (min-width: 48rem) {
  .sign-up .sign-up-wrapper .account-registration {
    flex-direction: row;
    align-items: flex-end;
    padding: 1.2em 0em;
  }
}
.sign-up .sign-up-wrapper .account-registration .title {
  font-size: 28px;
  font-weight: 600;
  line-height: normal;
}
.sign-up .sign-up-wrapper .account-registration .help-desk {
  font-size: 14px;
}
.sign-up .sign-up-wrapper .account-registration .help-desk .phone {
  font-weight: 600;
}
.sign-up .sign-up-wrapper .page-content {
  background-color: #fff;
  border-radius: 3px;
}
.sign-up .sign-up-wrapper .page-content .required {
  color: #d50000;
  font-size: 13px;
}
.sign-up .sign-up-wrapper .page-content .page-title {
  background-color: #ededed;
  padding-left: 1em;
  line-height: 52px;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.7px;
  color: #4a4a4a;
  height: 52px;
}
.sign-up .sign-up-wrapper .page-content .mat-step-label {
  color: #595555;
  font-size: 20px;
}
.sign-up .sign-up-wrapper .page-content .mat-step-label-active {
  font-weight: bold;
}
.sign-up .sign-up-wrapper .page-content .account-information {
  margin-bottom: 10px;
}
.sign-up .sign-up-wrapper .page-content .account-information .company-info {
  margin: 1em 1em;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 48rem) {
  .sign-up .sign-up-wrapper .page-content .account-information .company-info {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 48rem) {
  .sign-up .sign-up-wrapper .page-content .account-information .company-info .mat-mdc-form-field {
    width: 48%;
    padding: 0em 1em;
  }
}
.sign-up .sign-up-wrapper .page-content .account-information .contact-preference {
  color: #4a4a4a;
  display: flex;
  flex-direction: column;
  margin-left: 2em;
}
.sign-up .sign-up-wrapper .page-content .account-information .contact-preference > label {
  font-size: 19px;
  font-weight: 600;
  letter-spacing: 0.66px;
}
.sign-up .sign-up-wrapper .page-content .account-information .contact-preference .mat-radio-label-content {
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.46px;
}
.sign-up .sign-up-wrapper .page-content .account-information .contact-preference .mat-mdc-radio-button {
  margin-left: 3em;
}
.sign-up .sign-up-wrapper .page-content .account-information .contact-preference .mat-mdc-radio-button:first-child {
  margin-left: 0;
}
.sign-up .sign-up-wrapper .page-content .separator {
  border-top: 1px solid #ededed;
  margin: 1em 1em;
}
@media screen and (min-width: 48rem) {
  .sign-up .sign-up-wrapper .page-content .separator {
    width: 93%;
  }
}
.sign-up .sign-up-wrapper .page-content .actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: wrap;
}
@media screen and (min-width: 48rem) {
  .sign-up .sign-up-wrapper .page-content .actions {
    margin-right: 3em;
  }
}
.sign-up .sign-up-wrapper .page-content .actions button {
  margin-right: 2em;
}
.sign-up .sign-up-wrapper .page-content .actions button:last-child {
  margin-right: 0;
  height: 40px;
  border-radius: 19.5px;
}
.sign-up .sign-up-wrapper .page-content .captcha {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 1em;
}
@media screen and (min-width: 48rem) {
  .sign-up .sign-up-wrapper .page-content .captcha {
    margin-right: 3em;
  }
}
.sign-up .sign-up-wrapper .page-content .title {
  margin-top: 1em;
  font-size: 20px;
  font-weight: 600;
  width: 100%;
  letter-spacing: 0.66px;
  color: #4a4a4a;
}
@media screen and (min-width: 48rem) {
  .sign-up .sign-up-wrapper .page-content .title {
    margin: 0.5em 0 1em 1em;
  }
}
.sign-up .sign-up-wrapper .page-content .subtitle {
  margin: 0 0 1.5em 1.4em;
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  letter-spacing: 0.46px;
  color: #817b7b;
}
@media only screen and (max-width: 21.5rem) {
  .sign-up .sign-up-wrapper .page-content .subtitle {
    margin-left: 0;
  }
}
.sign-up .sign-up-wrapper .page-content .submitted {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 48rem) {
  .sign-up .sign-up-wrapper .page-content .submitted {
    min-height: 532px;
  }
}
.sign-up .sign-up-wrapper .page-content .submitted p {
  font-size: 26px;
  line-height: 1.54em;
  margin: 1em 3em;
  color: #595555;
  text-align: center;
}
.sign-up .sign-up-wrapper .page-content .submitted p:first-child {
  font-size: 30px;
  font-weight: 600;
}
.sign-up .sign-up-wrapper .page-content .submitted button {
  margin-top: 2em;
  border-radius: 19.5px;
}
.sign-up .sign-up-wrapper .page-content .mat-step-header:hover {
  background-color: transparent;
}
.sign-up .sign-up-wrapper .footer {
  font-size: 12px;
  color: #8c8585;
  padding: 2em 0em;
}
.sign-up .welcome {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.25rem;
  font-weight: 300;
  padding: 0.75em 0;
  letter-spacing: 0.025em;
  background-color: #004791;
  color: white;
}
/*# sourceMappingURL=sign-up.component.css.map */
