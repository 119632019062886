/* src/app/report-shipments-upload/report-shipments-upload.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 23.438rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.main {
  background: #fff;
  align-self: center;
  height: 100%;
}
.description {
  color: #5F5753;
  font-feature-settings: "liga" off, "clig" off;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 17.828px;
  padding-left: 30px;
}
.shipment-history-nav {
  box-shadow: none !important;
  border: solid thin #004791 !important;
  background-color: transparent !important;
  color: #004791 !important;
  border-radius: 3px 0px 0px 0px !important;
}
.tab-container {
  padding-left: 30px;
}
.tab-container .tab-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}
.tab-container .tab-header mat-tab-group {
  flex-grow: 1;
}
.tab-container .tab-header mat-checkbox {
  position: absolute;
  top: 0;
  left: 660px;
  align-self: flex-start;
  z-index: 1000;
}
.mat-mdc-tab-ripple {
  border: 1px solid #ccc;
  border-radius: 4px 4px 0 0;
  margin-right: 4px;
}
[id^=mat-tab-label-] {
  border-bottom: 1px solid #ccc;
}
.mdc-tab--active.mdc-tab-indicator--active > div {
  background-color: #fff;
  font-weight: bold;
  border: 2px solid #004791;
  border-bottom: 1px solid #ccc;
}
.mat-mdc-tab-group {
  align-items: flex-start;
}
.no-active-tab-indicator .mdc-tab-indicator {
  display: none;
}
/*# sourceMappingURL=report-shipments-upload.component.css.map */
