/* src/app/sign-up/billing-information/billing-information.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 21.5rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.billing-information {
  margin-bottom: 10px;
}
.billing-information .billing-address {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 48rem) {
  .billing-information .billing-address {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 48rem) {
  .billing-information .billing-address .country {
    margin-right: 50%;
  }
}
.billing-information .billing-address .title {
  margin-top: 2em !important;
}
.billing-information .billing-address .credit-card-title {
  margin-top: 0 !important;
}
.billing-information .billing-address .subtitle {
  color: #4a4a4a;
}
@media screen and (min-width: 48rem) {
  .billing-information .billing-address .mat-mdc-form-field {
    width: 48%;
    padding: 0em 1em;
  }
}
.billing-information .billing-address .mat-mdc-slide-toggle {
  width: 100%;
  margin-bottom: 1em;
}
@media screen and (min-width: 48rem) {
  .billing-information .billing-address .mat-mdc-slide-toggle {
    margin-left: 2em;
  }
}
.billing-information .billing-address .mat-slide-toggle-content {
  color: #817b7b;
  letter-spacing: 0.46px;
  font-weight: 600;
  font-size: 14px;
}
.billing-information .billing-address .twin-fields {
  width: 100%;
  margin-right: -1em;
}
@media screen and (min-width: 48rem) {
  .billing-information .billing-address .twin-fields {
    width: 50%;
  }
}
.billing-information .billing-address .twin-fields .mat-mdc-form-field {
  width: 100%;
}
@media screen and (min-width: 48rem) {
  .billing-information .billing-address .twin-fields .mat-mdc-form-field {
    width: 48%;
  }
}
.billing-information .billing-address .business-address {
  margin: 0.2em 0 0.5em 2em;
  font-size: 18px;
  line-height: 25px;
  color: #4a4a4a;
}
.billing-information .billing-address .mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #008464;
}
.billing-information .billing-address .mat-mdc-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #9ACDC1;
}
.billing-information .method-types {
  display: flex;
  margin: 1em;
  flex-direction: column;
}
@media screen and (min-width: 48rem) {
  .billing-information .method-types {
    flex-direction: row;
  }
}
.billing-information .method-types .method {
  flex-basis: 98%;
}
@media screen and (min-width: 48rem) {
  .billing-information .method-types .method {
    flex-basis: 48%;
  }
}
.billing-information .method-types .method p {
  font-size: 13px;
  color: #817b7b;
  padding-left: 2rem;
  line-height: 20px;
}
.billing-information .mat-radio-label-content {
  font-size: 14px;
  color: #4a4a4a;
  letter-spacing: 0.46px;
  font-weight: 600;
}
.billing-information .address {
  display: flex;
  flex-wrap: wrap;
}
.billing-information .billing-method {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
/*# sourceMappingURL=billing-information.component.css.map */
