/* src/app/billing/edit-credit-card/edit-credit-card.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 21.5rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.add-credit-card {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
}
.add-credit-card .mat-mdc-dialog-content {
  padding: 0 0 2rem 0;
}
.add-credit-card .terms {
  padding: 1rem 2rem;
  max-width: 60em;
}
.add-credit-card .form-actions {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  justify-content: center;
}
.iframe-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  min-height: 65vh;
}
.iframe-container .iframeCC {
  border: 0;
  width: 100%;
  height: 100%;
  flex: 1;
}
/*# sourceMappingURL=edit-credit-card.component.css.map */
