/* src/app/delivery-defense/dd-score-history/dd-score-history-find-pickup-location/dd-score-history-find-pickup-location.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 21.5rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
body {
  font-family: Roboto;
}
.find-pickup-location {
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0px 12px -3px rgba(0, 0, 0, 0.05), inset 0px 0 5px 0 rgba(0, 0, 0, 0.14);
}
.find-pickup-location h2 {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin-top: 35px;
  margin-left: 20px;
}
.find-pickup-location h2 a {
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
  margin-left: 20px;
}
.find-pickup-location .top-actions {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: row-reverse;
  margin-top: 20px;
  margin-right: 29px;
}
.find-pickup-location .top-actions button.close-icon {
  cursor: pointer;
  --icon-size: 2rem;
  background-color: transparent;
  border: none;
  width: var(--icon-size);
  height: var(--icon-size);
}
.find-pickup-location .top-actions button.close-icon:hover .mat-icon {
  font-weight: 700;
}
.find-pickup-location .top-actions button.close-icon .mat-icon {
  width: var(--icon-size);
  height: var(--icon-size);
  font-size: var(--icon-size);
}
.origin {
  margin-left: 20px;
}
.origin h3 {
  font-size: 16px;
}
.origin p {
  font-size: 14px;
}
.slider-range-container {
  background-color: #f5f5f5;
  position: relative;
}
.slider-range-container .slider-range-wrapper {
  display: flex;
  margin-left: 20px;
  align-items: center;
}
.slider-range-container .slider-range-wrapper .mat-slider {
  width: 300px;
}
.slider-range-container .slider-range-wrapper p {
  margin-top: 20px;
}
.slider-range-container .slider-range-wrapper ::ng-deep .mat-mdc-slider .mdc-slider__thumb .mdc-slider__thumb-knob {
  border-width: 0.35rem;
  border-color: #00857d;
}
.slider-range-container .slider-range-wrapper ::ng-deep .mat-mdc-slider-visual-thumb .mat-ripple {
  height: 0%;
  width: 0%;
}
.slider-range-container .slider-range-wrapper .range-label {
  margin-top: -50px;
  margin-left: 5px;
  font-size: 12px;
  position: absolute;
}
.slider-range-container .max-items-dropdown {
  margin-left: 20px;
  margin-top: 20px;
}
.pickup-locations span {
  margin-left: 15px;
}
.pickup-locations p {
  margin-left: 15px;
  font-size: 13px;
  font-weight: 400;
}
.divider {
  content: "";
  position: relative;
  width: 100%;
  height: 0.7px;
  background: #BFB8AF;
  margin-top: -10px;
  margin-bottom: 15px;
}
/*# sourceMappingURL=dd-score-history-find-pickup-location.component.css.map */
