/* src/app/login/login/login.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 21.5rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.login-jwt {
  width: 100vw;
  height: 100%;
  position: relative;
  background: url("./media/online-portal-login-background.webp") no-repeat fixed center center;
  background-size: cover;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
}
.login-jwt .login-content-wrapper-v2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  justify-content: center;
  background-color: transparent;
  width: 100%;
  flex-grow: 1;
}
@media screen and (min-width: 48rem) {
  .login-jwt .login-content-wrapper-v2 {
    margin: 0 auto;
    column-gap: 10%;
  }
}
.login-jwt .login-content-wrapper-v2 .login-content-items {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
}
.login-jwt .login-content-wrapper-v2 .login-content-items.login-form {
  max-width: 27.5em;
}
.login-jwt .login-content-wrapper-v2 .login-content-items .login-content-item {
  padding: 2em;
  background-color: rgba(255, 255, 255, 0.9);
  margin-bottom: 2px;
  width: 100%;
}
@media screen and (min-width: 48rem) {
  .login-jwt .login-content-wrapper-v2 .login-content-items .login-content-item {
    padding: 2em 3.8125em;
  }
}
@media screen and (min-width: 75rem) {
  .login-jwt .login-content-wrapper-v2 .login-content-items .login-content-item {
    padding: 2.25em 4.25em;
  }
}
@media screen and (min-width: 64rem) {
  .login-jwt .login-content-wrapper-v2 .login-content-items .login-content-item {
    width: 27.5em;
  }
}
.login-jwt .login-content-wrapper-v2 .login-content-items .login-content-item .page-title {
  font-size: 1.125em;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin: 1.875em 0;
}
.login-jwt .login-content-wrapper-v2 .login-content-items .login-content-item .ups-form {
  background-color: transparent;
}
.login-jwt .login-content-wrapper-v2 .login-content-items .login-content-item .ups-form .form-body {
  background-color: inherit;
}
.login-jwt .login-content-wrapper-v2 .login-content-items .login-content-item .ups-form .form-footer {
  background-color: inherit;
}
.login-jwt .login-content-wrapper-v2 .login-content-items .login-content-item .ups-form .form-footer a {
  margin-bottom: 0;
}
.login-jwt .login-content-wrapper-v2 .login-content-items .login-content-item .ups-form .form-footer .material-icons {
  position: relative;
  top: 0.35em;
}
.login-jwt .login-content-wrapper-v2 .login-content-items .login-content-item.signup {
  padding-top: 0;
}
.login-jwt .login-content-wrapper-v2 .login-content-items .login-content-item.signup .page-title {
  margin: 2.75em 0 1.25em;
}
.login-jwt .login-content-wrapper-v2 .login-content-items .login-content-item.signup .create-account {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.login-jwt .login-content-wrapper-v2 .login-content-items .login-content-item.signup .create-account .mat-mdc-raised-button {
  align-self: stretch;
  height: 3.125em;
  font-size: 1rem;
}
.login-jwt .login-content-wrapper-v2 .login-content-items .login-content-item.signup a,
.login-jwt .login-content-wrapper-v2 .login-content-items .login-content-item.signup span {
  font-size: 0.875em;
  letter-spacing: 0.5px;
  line-height: 1.64;
}
.login-jwt .login-content-wrapper-v2 .login-content-items .login-content-item.signup .trouble {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 1.5625em;
  text-align: center;
}
.login-jwt .login-content-wrapper-v2 .login-content-items .login-content-item.signup .mat-mdc-raised-button {
  width: 100%;
}
.login-jwt .login-content-wrapper-v2 .parcelpro-diamond {
  aspect-ratio: auto 1441/1392;
  width: 100%;
  max-width: 100%;
  height: auto;
}
@media screen and (max-width: 48rem) {
  .login-jwt .login-content-wrapper-v2 .parcelpro-diamond {
    display: none;
  }
}
@media screen and (min-width: 48rem) {
  .login-jwt .login-content-wrapper-v2 .parcelpro-diamond {
    min-width: 28.125rem;
  }
}
@media screen and (min-width: 75rem) {
  .login-jwt .login-content-wrapper-v2 .parcelpro-diamond {
    min-width: 37.5rem;
  }
}
.login-jwt .login-footer {
  margin-top: auto;
}
.login-jwt .form-body {
  padding: 0;
}
.login-jwt .login-transparent-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 1.5em;
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 64rem) {
  .login-jwt .login-transparent-wrapper {
    width: 50%;
  }
}
.login-jwt .login-transparent-wrapper .login-content-wrapper {
  padding: 1.5em;
  background-color: rgba(89, 85, 85, 0);
  margin: auto 0;
}
@media screen and (min-width: 48rem) {
  .login-jwt .login-transparent-wrapper .login-content-wrapper {
    padding: 2.5em;
  }
}
@media screen and (min-width: 64rem) {
  .login-jwt .login-transparent-wrapper .login-content-wrapper {
    background-color: transparent;
  }
}
.login-jwt .logo {
  margin-bottom: 2em;
}
.login-jwt .logo ::ng-deep img {
  margin: 0 auto;
}
.login-jwt .form-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 2rem 0 0 0;
}
.login-jwt .form-footer .mat-mdc-raised-button {
  align-self: stretch;
  height: 3.125em;
}
.login-jwt .form-footer a,
.login-jwt .form-footer span {
  font-size: 0.8125em;
}
.login-jwt .form-footer > a {
  margin: 2em 0;
}
.login-jwt .form-footer .form-actions .mat-mdc-raised-button {
  width: 100%;
}
.login-jwt .form-footer .action .mdc-button {
  min-width: 8rem;
  height: auto;
  padding: 0.5rem;
}
.recaptcha-notice {
  line-height: 1.5;
  font-size: 0.7rem;
  padding: 1em 0;
  text-align: center;
  color: #8C857E;
}
.recaptcha-notice a {
  font-size: 1em !important;
}
:host {
  width: 100%;
  height: 100%;
}
/*# sourceMappingURL=login.component.css.map */
