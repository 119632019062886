/* src/app/delivery-defense/dd-manage-subscription/dd-feedback/dd-feedback.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 21.5rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.feedback-dialog {
  width: 397px;
  flex-shrink: 0;
  border-radius: 4px;
  background: #FFF;
}
.feedback-dialog .contentPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.feedback-dialog .contentPanel .dialog-header {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 2em 1em 0 1em;
  width: 100%;
  margin-top: 1em;
  position: relative !important;
}
.feedback-dialog .contentPanel .dialog-header .header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 11px;
}
.feedback-dialog .contentPanel .dialog-header .header h1 {
  width: 275px;
  height: 30px;
  color: #000;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}
.feedback-dialog .contentPanel .dialog-header .header h2 {
  width: 278px;
  height: 45px;
  color: #5F5753;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.feedback-dialog .contentPanel .thanksPanel {
  display: flex;
  width: 300px;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2rem 0 10rem;
}
.feedback-dialog .contentPanel .thanksPanel .guaranteedbadge {
  width: 100px;
  height: 100px;
}
.feedback-dialog .contentPanel .thanksPanel .thanksNote h3 {
  color: #000;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.feedback-dialog .contentPanel .thanksPanel .thanksNote h4 {
  color: #000;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 20px;
}
.feedback-dialog .contentPanel .emojiPanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}
.feedback-dialog .contentPanel .emojiPanel h1 {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.feedback-dialog .contentPanel .description {
  width: 100%;
  padding: 0 3rem;
}
.feedback-dialog .contentPanel .description h2 {
  width: 211px;
  height: 22px;
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.feedback-dialog .contentPanel .description .mat-mdc-form-field {
  width: 100%;
}
.feedback-dialog .contentPanel .description .descrpPanel {
  resize: none;
  display: flex;
  width: 300px;
  height: 83px;
  padding: 0px 10px 10px 10px;
  align-items: flex-start;
  gap: 16px;
  border-radius: 4px;
  border: 1px solid #5F5753;
  background: #FFF;
}
.feedback-dialog .contentPanel .submit {
  display: flex;
  width: 300px;
  height: 43px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 2em;
  margin-top: 1rem;
}
/*# sourceMappingURL=dd-feedback.component.css.map */
