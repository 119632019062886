/* src/app/delivery-defense/dd-score-history/dd-score-history-advanced-filter/dd-score-history-advanced-filter.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 21.5rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.top-actions {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: row-reverse;
  margin-top: 30px;
  margin-right: 15px;
}
.top-actions button.close-icon {
  --icon-size: 2.5rem;
  background-color: transparent;
  border: none;
  width: var(--icon-size);
  height: var(--icon-size);
  cursor: pointer;
}
.top-actions button.close-icon:hover .mat-icon {
  font-weight: 700;
}
.top-actions button.close-icon .mat-icon {
  width: var(--icon-size);
  height: var(--icon-size);
  font-size: var(--icon-size);
}
.title {
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 400;
  position: relative;
}
.title::before {
  content: "";
  display: block;
  position: absolute;
  bottom: -1.5rem;
  left: 0;
  width: 4.5rem;
  height: 0.25rem;
  background-color: #004791;
}
h3 {
  margin-bottom: 8px;
  color: var(--ups-brand-colors-ups-grey-1, #5f5753);
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 3rem;
  position: relative;
}
.score-history-filter-container {
  position: relative;
  margin-top: 50px;
}
.score-history-filter-container .score-history-balance-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (min-width: 64rem) {
  .score-history-filter-container .score-history-balance-container {
    flex-direction: row;
  }
}
.score-history-filter-container .score-history-balance-container .score-history-balance-form {
  height: 3.5rem;
  background: var(--ups-brand-colors-ups-white, #fff);
  width: 100%;
  margin-bottom: 20px;
}
@media screen and (min-width: 48rem) {
  .score-history-filter-container .score-history-balance-container .score-history-balance-form {
    margin-bottom: 0px;
  }
}
.score-history-filter-container .score-history-balance-container .score-history-balance-form .mat-mdc-form-field {
  width: inherit;
}
.score-history-filter-container .score-history-balance-container .score-history-balance-form .mat-mdc-form-field .score-history-value-input {
  display: flex;
  flex-direction: row;
}
@media screen and (min-width: 64rem) {
  .score-history-filter-container .score-history-balance-container .minimum {
    margin-right: 20px;
  }
}
@media screen and (min-width: 64rem) {
  .score-history-filter-container .score-history-balance-container .maximum {
    margin-left: 20px;
  }
}
.score-history-filter-container .score-history-balance-container .score-history-balance-divider {
  display: none;
}
@media screen and (min-width: 64rem) {
  .score-history-filter-container .score-history-balance-container .score-history-balance-divider {
    display: block;
    content: "";
    position: relative;
    width: 20px;
    height: 2px;
    background: #53535f;
    margin-top: 22px;
    min-width: 20px;
  }
}
.score-history-filter-container .score-history-error-message {
  margin-top: 15px;
  max-width: 500px;
}
.score-history-filter-container .score-history-error-message small {
  background-color: var(--ups-error, #c91a13);
  color: white;
  border-radius: 20px;
  padding: 0.75em;
  font-size: 12px;
  font-weight: 700;
}
.score-history-filter-container .score-history-slider-container {
  width: 100%;
  margin-top: 5px;
  position: relative;
}
.score-history-filter-container .range-slider {
  position: relative;
  margin-right: 15px;
}
.score-history-filter-container .range-slider .label {
  position: absolute;
  top: 1.5rem;
  width: auto;
  height: 1em;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  color: #5F5753;
}
.score-history-filter-container .range-slider .label.min {
  top: 4em;
  left: 0px;
}
.score-history-filter-container .range-slider .label.max {
  top: 4em;
  right: -15px;
}
.score-history-filter-container ::ng-deep .mat-mdc-slider .mdc-slider__track--inactive {
  background-color: #757583;
  opacity: 1;
}
.score-history-filter-container ::ng-deep .mat-mdc-slider-visual-thumb .mat-ripple {
  height: 0%;
  width: 0%;
}
.score-history-filter-container ::ng-deep .mat-mdc-slider .mdc-slider__thumb-knob {
  box-shadow: var(--mdc-slider-handle-elevation);
  border-width: 7px;
  border-color: white;
  width: 26px;
  height: 26px;
}
.divider {
  content: "";
  position: relative;
  width: 100%;
  height: 0.7px;
  background: #bfb8af;
  margin-top: 25px;
}
.bottom-actions {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  justify-content: space-around;
  column-gap: 0.75rem;
  padding: 2rem 0 3rem 0;
}
@media screen and (min-width: 48rem) {
  .bottom-actions {
    justify-content: flex-start;
    padding: 2rem 0 0 0;
  }
}
.bottom-actions .ups-button.primary {
  font-family: Roboto;
  border-radius: 3px;
  border: 1.401px solid #00857d;
  background-color: #00857d;
  color: white;
  cursor: pointer;
  padding: 10px 20px;
}
.bottom-actions .ups-button.secondary {
  font-family: Roboto;
  border-radius: 3px;
  border: 2px solid #00857d;
  background-color: white;
  color: #00857d;
  cursor: pointer;
  padding: 10px 20px;
}
::ng-deep .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
}
/*# sourceMappingURL=dd-score-history-advanced-filter.component.css.map */
