/* src/app/delivery-defense/delivery-defense-faq/delivery-defense-faq.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.dd-faq,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.dd-faq,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 21.5rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.dd-faq {
  font-size: 1rem;
  margin-left: 1em;
  margin-top: 3em;
}
.dd-faq .content-wrapper {
  width: 100%;
}
@media screen and (min-width: 64rem) {
  .dd-faq .content-wrapper {
    width: 80%;
    margin: auto;
  }
}
.dd-faq .content-wrapper .header .titlePanel {
  display: flex;
  width: 100%;
  align-items: flex-end;
  gap: 370px;
  color: var(--ups-brand-colors-ups-black, #121212);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px;
}
.dd-faq .content-wrapper .expansionPanel {
  display: flex;
  padding: 3px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
  margin-top: 16px;
  border-radius: 4px;
  background: #FFF;
  box-shadow:
    0px 15px 32px -3px rgba(0, 0, 0, 0.08),
    0px 4px 12px -2px rgba(0, 0, 0, 0.08),
    0px 2px 6px 0px rgba(0, 0, 0, 0.06);
  margin-right: 2em;
}
.dd-faq .content-wrapper .expansionPanel .panelHeader {
  color: var(--ups-brand-colors-ups-black, #121212);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  align-self: stretch;
  margin-top: 13px;
  margin-bottom: 4px;
}
@media screen and (min-width: 48rem) {
  .dd-faq .content-wrapper .expansionPanel .panelHeader {
    margin-top: 9px;
  }
}
.dd-faq .content-wrapper .panelBody {
  color: var(--ups-brand-colors-ups-black, #121212);
  font-feature-settings: "clig" off, "liga" off;
  align-self: stretch;
  font-family: "Roboto Flex", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: -13px;
  width: 100%;
}
.dd-faq .content-wrapper .panelBody a {
  cursor: pointer;
  color: var(--ups-brand-colors-ups-blue, #0662BB);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
}
.dd-faq .content-wrapper .trademark {
  font-size: 8px;
  margin-top: 5px;
  margin-left: -3px;
}
.dd-faq .content-wrapper .trademark.in-header {
  margin-left: 0;
  margin-right: 5px;
}
@media screen and (max-width: 48rem) {
  .dd-faq .content-wrapper .trademark {
    display: none;
  }
}
::ng-deep .panelHeader > .mat-expansion-indicator:after {
  color: black;
}
@media screen and (min-width: 48rem) {
  :host .dd-faq {
    max-width: 100%;
  }
}
/*# sourceMappingURL=delivery-defense-faq.component.css.map */
