/* src/app/login/login/login-footer/login-footer.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 21.5rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.login-footer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 1rem;
  background-color: rgba(89, 85, 85, 0.8);
  color: rgba(255, 255, 255, 0.87);
  padding: 1em;
  align-items: center;
}
@media screen and (min-width: 64rem) {
  .login-footer {
    color: #242424;
    background-color: rgba(255, 255, 255, 0.5);
  }
}
.login-footer a {
  color: inherit;
  font-weight: 500;
}
.login-footer .links {
  font-size: 0.6875em;
  max-width: 1050px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.login-footer .links .link {
  min-width: 8em;
  padding: 0 1em;
  text-align: center;
}
.login-footer .links .link.email {
  order: 4;
  width: 100%;
}
.login-footer .links .link.privacy {
  order: 1;
}
.login-footer .links .link.terms {
  order: 2;
}
.login-footer .links .link.website {
  order: 3;
}
@media screen and (min-width: 48rem) {
  .login-footer .links .link.email {
    order: 0;
    width: auto;
  }
}
.login-footer .links.compresswidth {
  width: 836px;
}
.login-footer .links.compresswidthuk {
  max-width: 1193px !important;
}
.login-footer .links.compresswidthfr {
  max-width: 1193px !important;
}
.login-footer .linksTab {
  font-size: 0.6875em;
  max-width: 668px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.login-footer .linksTab .link {
  min-width: 8em;
  padding: 0 1em;
  text-align: center;
}
@media screen and (min-width: 48rem) {
  .login-footer .linksTab .link.email {
    order: 0;
    width: auto;
  }
}
.login-footer .footerPanel {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
.login-footer .disclaimer,
.login-footer .copyrights {
  font-size: 0.6875em;
  line-height: 1.27;
  text-align: center;
}
/*# sourceMappingURL=login-footer.component.css.map */
