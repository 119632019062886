import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Angulartics2Module } from 'angulartics2';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaModule, ReCaptchaV3Service } from 'ng-recaptcha';
import { NgxCaptchaModule } from 'ngx-captcha';
import { CookieService } from 'ngx-cookie-service';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { environment } from '../environments/environment';
import { AccountModule } from './account/account.module';
import { AddressBookModule } from './address-book/address-book.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BillingModule } from './billing/billing.module';
import { CoreModule } from './core/core.module';
import { HtmlDecoderInterceptor } from './core/services/http/html-decoder.interceptor';
import { TokenInterceptor } from './core/services/http/token.interceptor';
import { CostEstimatorModule } from './cost-estimator/cost-estimator.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { DeliveryDefenseModule } from './delivery-defense/delivery-defense.module';
import { DropShipmentModule } from './drop-shipment/drop-shipment.module';
import { GroupPolicyITComponent } from './group-policy-it/group-policy-it.component';
import { HistoryModule } from './history/history.module';
import { LoginModule } from './login/login.module';
import { LogoutComponent } from './logout/logout.component';
import { PreLoadService } from './preLoad.service';
import { S3FileDownloaderModule } from './shared/components/s3-file-downloader/s3-file-downloader.module';
import { SharedModule } from './shared/shared.module';
import { SignUpModule } from './sign-up/sign-up.module';
import { SupportModule } from './support/support.module';
import { TermsModule } from './terms/terms.module';
import { WorldShipUploadModule } from './world-ship-upload/world-ship-upload.module';

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function preLoadServiceFactory(provider: PreLoadService) {
    return () => provider.load();
}

@NgModule({
    bootstrap: [
        AppComponent,
    ],
    declarations: [
        AppComponent,
        LogoutComponent,
        GroupPolicyITComponent,
    ], imports: [
        CoreModule,
        NgxCaptchaModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        LoginModule,
        SignUpModule,
        SharedModule,
        DashboardModule,
        DeliveryDefenseModule,
        HistoryModule,
        AddressBookModule,
        AccountModule,
        BillingModule,
        SupportModule,
        CostEstimatorModule,
        DropShipmentModule,
        TermsModule,

        MatExpansionModule,
        MatTabsModule,
        WorldShipUploadModule,
        S3FileDownloaderModule,
        MatButtonToggleModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient],
            },
        }),
        Angulartics2Module.forRoot({
            pageTracking: {
                clearIds: true,
                // idsRegExp: /^[a-z]\d+$/,
                clearQueryParams: true,
            },
        }),
        HammerModule,
        NgxSkeletonLoaderModule.forRoot({ animation: 'progress' }),
        RecaptchaModule,
        HistoryModule,
    ], providers: [
        PreLoadService,
        { provide: APP_INITIALIZER, useFactory: preLoadServiceFactory, deps: [PreLoadService], multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HtmlDecoderInterceptor, multi: true },
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' } },
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
        CookieService,
        ReCaptchaV3Service,
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCAPTCHA?.siteKey },
        provideHttpClient(withInterceptorsFromDi()),
    ],
})
export class AppModule {
}
