/* src/app/delivery-defense/dd-address-confidence-tool/dd-upload-address-file/dd-upload-address-file-review-errors/dd-upload-address-file-review-errors.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 21.5rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.review-errors-container .review-errors-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.review-errors-container .review-errors-header h2 {
  color: var(--UPS-Brand-Colors-UPS-Black, #121212);
  text-align: center;
  font-size: 20.799px;
  font-weight: 400;
  margin: 10px 0 13px 0;
}
.review-errors-container .review-errors-header p {
  color: var(--UPSC-Gray-1, #4A4A4C);
  text-align: center;
  font-size: 11.885px;
  font-weight: 400;
  margin: 0;
}
.review-errors-container .review-errors-progress-bar-container {
  height: 2.971px;
  width: 90%;
  background:
    linear-gradient(
      to right,
      var(--Capital-Color-Libary-UPSC-Teal, #00857D) 0%,
      var(--Capital-Color-Libary-UPSC-Teal, #00857D) 50%,
      #757583 50%,
      #757583 100%);
  box-shadow: -0.743px 0px 0px 0px #0A8080;
  position: relative;
  margin: 35px auto 35px auto;
  display: flex;
  justify-content: space-between;
}
@media screen and (min-width: 48rem) {
  .review-errors-container .review-errors-progress-bar-container {
    width: 60%;
    margin-top: 40px;
  }
}
.review-errors-container .review-errors-progress-bar-container .progress-bar-wrapper .indicator-icon {
  width: 8.914px;
  height: 8.914px;
  position: absolute;
  top: -3px;
}
.review-errors-container .review-errors-progress-bar-container .progress-bar-wrapper .start-icon {
  left: -5px;
}
.review-errors-container .review-errors-progress-bar-container .progress-bar-wrapper .middle-icon {
  width: 16.342px;
  height: 16.342px;
  left: 50%;
  transform: translateX(-50%);
  top: -7px;
}
.review-errors-container .review-errors-progress-bar-container .progress-bar-wrapper .end-icon {
  right: -5px;
}
.review-errors-container .review-errors-progress-bar-container .progress-bar-wrapper span {
  color: var(--UPSC-Gray-1, #4A4A4C);
  text-align: center;
  font-size: 11.885px;
  font-weight: 400;
  line-height: 17.828px;
  position: absolute;
  margin-top: 12px;
}
.review-errors-container .review-errors-progress-bar-container .progress-bar-wrapper .start-span {
  left: -35px;
}
.review-errors-container .review-errors-progress-bar-container .progress-bar-wrapper .middle-span {
  left: 50%;
  transform: translateX(-50%);
}
.review-errors-container .review-errors-progress-bar-container .progress-bar-wrapper .end-span {
  right: -35px;
}
.review-errors-container .review-errors-message {
  border-left: 2.229px solid var(--UPS-Brand-Colors-UPS-Error-Red, #DF2901);
  background: var(--Semantic-Colors-UPS-Error-Red-Light, #FCEAE6);
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 11.89px;
}
.review-errors-container .review-errors-message p {
  color: var(--UPS-Brand-Colors-UPS-Black, #121212);
  font-size: 11.885px;
  font-weight: 400;
}
.review-errors-container .review-errors-message .error-icon {
  width: 11.934px;
  height: 11.934px;
  margin: 0 7.43px 3px 8.91px;
}
.review-errors-container .no-errors-message {
  border-left: 2.229px solid var(--UPS-Brand-Colors-UPS-Success-Green, #29A855);
  background: var(--Semantic-Colors-UPS-Success-Green-Light, #D4EEDD);
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 11.89px;
}
.review-errors-container .no-errors-message p {
  color: var(--UPS-Brand-Colors-UPS-Black, #121212);
  font-size: 11.885px;
  font-weight: 400;
}
.review-errors-container .no-errors-message .error-icon {
  width: 11.934px;
  height: 11.934px;
  margin: 0 7.43px 3px 8.91px;
}
.review-errors-container .review-errors-info {
  display: flex;
}
.review-errors-container .review-errors-info .review-errors-wrapper {
  margin-right: 6.69px;
}
.review-errors-container .review-errors-info .error-icon {
  width: 11.934px;
  height: 11.934px;
  vertical-align: middle;
}
.review-errors-container .review-errors-info span {
  color: var(--UPS-Brand-Colors-UPS-Black, #121212);
  font-family: Roboto;
  font-size: 11.885px;
  font-weight: 400;
}
.review-errors-container .action-toolbar {
  display: flex;
  justify-content: flex-end;
  margin-top: 11.89px;
}
.review-errors-container .action-toolbar .remove-all-error-addresses-btn {
  color: #004791;
  font-size: 11.885px;
  font-weight: 500;
  border-radius: 29.713px;
  border: 1.486px solid #004791;
  padding: 7.428px 17.828px;
}
.review-errors-container .action-toolbar .remove-all-error-addresses-btn[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
.review-errors-container .action-toolbar .show-errors-toggle-slide {
  border-radius: 5.943px;
  background: var(--UPS-Brand-Colors-UPS-White, #FFF);
  box-shadow: 0px 2.971px 8.914px -2.229px rgba(0, 0, 0, 0.05), 0px 1.486px 3.714px 0px rgba(0, 0, 0, 0.14);
  color: var(--UPSC-Gray-1, #4A4A4C);
  font-size: 11.885px;
  font-weight: 400;
  margin-left: 8.914px;
  border-radius: 5.943px;
}
.review-errors-container .action-toolbar .show-errors-toggle-slide .mat-button-toggle-appearance-standard.mat-button-toggle-checked {
  background: #004791;
  color: var(--UPS-Brand-Colors-UPS-White, #FFF);
}
.review-errors-container .action-toolbar .show-errors-toggle-slide .mat-button-toggle-appearance-standard {
  border-radius: 5.943px;
  padding: 1px 3px 1px 1px;
}
.review-errors-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  background: #FFF;
}
@media screen and (min-width: 48rem) {
  .review-errors-content {
    margin: 0 auto;
    position: relative;
  }
}
.review-errors-content .review-errors-grid-container {
  display: block;
  width: 100%;
  margin-top: 10px;
}
.review-errors-content .review-errors-grid-container .grid-wrapper {
  position: relative;
  width: 100%;
  overflow: auto;
  box-shadow: 0 4px 12px -3px rgba(0, 0, 0, 0.05), 0px 0 5px 0 rgba(0, 0, 0, 0.14);
}
.review-errors-content .review-errors-grid-container .grid-wrapper.show-edit {
  min-height: 23rem;
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table {
  width: 100%;
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .error-message {
  color: var(--UPS-Brand-Colors-UPS-Error-Red, #DF2901);
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .more-padding-left {
  padding-left: 93px;
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .more-min-width {
  min-width: 281px;
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table th {
  background-color: var(--UPSC-Gray-1, #4A4A4C);
  color: var(--UPS-Brand-Colors-UPS-White, #FFF);
  font-family: Roboto;
  font-size: 11.885px;
  font-weight: 500;
  min-width: 205px;
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table td {
  color: var(--UPS-Brand-Colors-UPS-Black, #121212);
  font-family: Roboto;
  font-size: 11.885px;
  font-weight: 400;
  padding: 17.83px 0px 17.83px 16px;
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .ups-table-rows .header-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .street-container {
  position: relative;
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .street-container .icon-container {
  position: absolute;
  top: 0.75rem;
  padding-left: 12px;
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .street-container .icon-container .no-icon {
  padding-left: 14px;
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .street-container .icon-container .img-wrapper {
  display: inline;
  padding-left: 10px;
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .street-container .icon-container .cursor-pointer {
  cursor: pointer;
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr {
  position: relative;
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container {
  position: absolute;
  width: 99%;
  margin: 0 3.71px;
  background: var(--Capital-Color-Libary-UPSC-Grey-5, #EFEFF2);
  border: 1px solid black;
  left: 0;
  top: 3rem;
  z-index: 1;
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .polygon-container {
  position: absolute;
  top: -1.05rem;
  left: 0.45rem;
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .polygon-container .polygon-1 {
  fill: var(--Capital-Color-Libary-UPSC-Grey-5, #EFEFF2);
  stroke: black;
  stroke-width: 1;
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .edit-address-panel {
  margin-left: 17.43px;
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .edit-address-panel .edit-address-wrapper {
  display: flex;
  justify-content: left;
  position: relative;
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .edit-address-panel .edit-address-wrapper h3 {
  color: var(--UPS-Brand-Colors-UPS-Black, #121212);
  text-align: center;
  font-family: Roboto;
  font-size: 17.828px;
  font-weight: 400;
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .edit-address-panel .edit-address-wrapper .close-btn {
  width: 8.914px;
  height: 8.914px;
  position: absolute;
  right: 0.8rem;
  top: 0.8rem;
  cursor: pointer;
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .divider {
  width: 53.484px;
  height: 2.971px;
  background: var(--Capital-Color-Libary-UPSC-Teal, #00857D);
  margin-top: -10px;
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .form-field-address-edit-container {
  width: 100%;
  margin-top: 15px;
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .form-field-address-edit-container .form-field-address-edit,
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .form-field-address-edit-container .error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media screen and (min-width: 48rem) {
  .review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .form-field-address-edit-container .form-field-address-edit,
  .review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .form-field-address-edit-container .error-container {
    flex-direction: row;
  }
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .form-field-address-edit-container .form-field-address-edit .has-errors ::ng-deep .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .form-field-address-edit-container .error-container .has-errors ::ng-deep .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .form-field-address-edit-container .form-field-address-edit .has-errors ::ng-deep .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .form-field-address-edit-container .error-container .has-errors ::ng-deep .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .form-field-address-edit-container .form-field-address-edit .has-errors ::ng-deep .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .form-field-address-edit-container .error-container .has-errors ::ng-deep .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
  border-color: var(--UPS-Brand-Colors-UPS-Error-Red, #DF2901);
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .form-field-address-edit-container .form-field-address-edit .has-errors mat-label,
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .form-field-address-edit-container .error-container .has-errors mat-label {
  color: var(--UPS-Brand-Colors-UPS-Error-Red, #DF2901);
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .form-field-address-edit-container .form-field-address-edit .address-edit-field,
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .form-field-address-edit-container .error-container .address-edit-field {
  display: flex;
  justify-content: space-between;
  font-size: 13.5px;
  width: 100%;
  background-color: white;
  color: black;
  margin-bottom: 20px;
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .form-field-address-edit-container .form-field-address-edit .address-edit-field ::ng-deep .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)::after,
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .form-field-address-edit-container .error-container .address-edit-field ::ng-deep .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)::after {
  content: "";
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .form-field-address-edit-container .form-field-address-edit .address-edit-field input,
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .form-field-address-edit-container .error-container .address-edit-field input {
  display: block;
  color: black;
  font-family: "Roboto Flex", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  text-overflow: ellipsis;
}
@media screen and (min-width: 48rem) {
  .review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .form-field-address-edit-container .error-container {
    flex-direction: row;
  }
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .form-field-address-edit-container .error-container .error-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: 13.5px;
  width: 100%;
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .form-field-address-edit-container .error-container .error-wrapper .error-messages {
  color: var(--UPS-Brand-Colors-UPS-Error-Red, #DF2901);
  font-family: Roboto;
  font-size: 11.885px;
  font-style: normal;
  font-weight: 400;
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .form-field-address-edit-container .street {
  min-width: 260px;
  margin-right: 17.83px;
}
@media screen and (min-width: 48rem) {
  .review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .form-field-address-edit-container .street {
    margin-right: 0;
  }
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .form-field-address-edit-container .city {
  min-width: 160px;
  margin-right: 17.83px;
}
@media screen and (min-width: 48rem) {
  .review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .form-field-address-edit-container .city {
    margin-left: 9px;
    margin-right: 0;
  }
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .form-field-address-edit-container .state {
  min-width: 149px;
  margin-right: 17.83px;
}
@media screen and (min-width: 48rem) {
  .review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .form-field-address-edit-container .state {
    margin-left: 9px;
    margin-right: 0;
  }
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .form-field-address-edit-container .zip {
  min-width: 160px;
  margin-right: 17.83px;
}
@media screen and (min-width: 48rem) {
  .review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .form-field-address-edit-container .zip {
    margin-left: 9px;
  }
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .cancel-or-update-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 1rem;
  padding-bottom: 20px;
  margin-right: 12px;
  margin-top: 10px;
}
@media screen and (min-width: 48rem) {
  .review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .cancel-or-update-container {
    padding-bottom: 12px;
  }
}
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .cancel-or-update-container .mdc-button {
  border-radius: 9999px;
  font-size: 0.75rem;
}
.review-errors-content .review-errors-grid-container .pagination-wrapper {
  margin: 10px 0 50px 0;
  border-radius: 0 0 2.984px 2.984px;
  border-top: 0.746px solid var(--UPSC-Gray-2, #757583);
  background: #FFF;
  box-shadow: 0 4px 12px -3px rgba(0, 0, 0, 0.05), inset 0px 0 5px 0 rgba(0, 0, 0, 0.14);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row-reverse;
  justify-content: center;
}
@media screen and (min-width: 48rem) {
  .review-errors-content .review-errors-grid-container .pagination-wrapper {
    padding: 0;
  }
}
.review-errors-content .review-errors-grid-container .pagination-wrapper ::ng-deep pagination-controls .ngx-pagination {
  justify-content: center;
}
@media screen and (min-width: 48rem) {
  .review-errors-content .review-errors-grid-container .pagination-wrapper ::ng-deep pagination-controls .ngx-pagination {
    justify-content: flex-end;
  }
}
.review-errors-content .review-errors-grid-container ::ng-deep upsc-custom-paginator .inner .disabled-link {
  color: #AFB1BF;
}
.review-errors-content .review-errors-grid-container ::ng-deep upsc-custom-paginator #outer {
  height: auto;
  padding-top: 0;
}
@media screen and (min-width: 25rem) and (max-width: 80rem) and (min-height: 25rem) and (max-height: 45rem) {
  .review-errors-content .review-errors-grid-container ::ng-deep upsc-custom-paginator #outer {
    height: 80px;
  }
}
.review-errors-content .review-errors-grid-container ::ng-deep upsc-custom-paginator .mat-mdc-text-field-wrapper {
  width: 110%;
  margin-top: 20px;
}
.review-errors-content .review-errors-grid-container .cancel-or-submit-container,
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .cancel-or-update-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 1rem;
}
.review-errors-content .review-errors-grid-container .cancel-or-submit-container .mdc-button,
.review-errors-content .review-errors-grid-container .grid-wrapper .ups-table .my-tr .edit-address-container .cancel-or-update-container .mdc-button {
  border-radius: 9999px;
  font-size: 0.75rem;
}
.review-errors-content .mat-stroked-btn[disabled] {
  opacity: 0.5;
  background: var(--UPSC-Gray-4, #D8D7DF);
  cursor: not-allowed;
}
/*# sourceMappingURL=dd-upload-address-file-review-errors.component.css.map */
