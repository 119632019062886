/* src/app/delivery-defense/dd-manage-subscription/dd-manage-subscription.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 21.5rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.subscription-layout {
  width: 100%;
}
.subscription-layout .subscription-content {
  display: flex;
  width: 100%;
  padding: 50px 34px 0px 34px;
  flex-direction: column;
  align-items: center;
  gap: 38px;
  flex-shrink: 0;
  background: #FFF;
}
@media screen and (min-width: 48rem) {
  .subscription-layout .subscription-content {
    max-width: 1150px;
    margin: 0 auto;
    position: relative;
  }
}
.subscription-layout .subscription-content .header-panel {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 3px;
  justify-content: space-between;
}
@media screen and (min-width: 48rem) {
  .subscription-layout .subscription-content .header-panel {
    flex-direction: row;
    padding: 0 3.25em;
  }
}
.subscription-layout .subscription-content .header-panel .header {
  width: 50%;
  height: 57px;
  flex-shrink: 0;
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 32px;
  margin-bottom: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 56px;
}
@media screen and (min-width: 48rem) {
  .subscription-layout .subscription-content .header-panel .header {
    font-size: 40px;
    margin-bottom: 0px;
  }
}
.subscription-layout .subscription-content .header-panel upsc-dd-remaining-credits {
  width: 100%;
  max-width: 21.5rem;
  padding: 0;
}
.subscription-layout .subscription-content .header-panel .guage-panel {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
.subscription-layout .subscription-content .subscriber-panel-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  gap: 55px;
}
@media screen and (min-width: 48rem) {
  .subscription-layout .subscription-content .subscriber-panel-container {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.subscription-layout .subscription-content .subscriber-panel-container .subscriber {
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.25));
  width: 100%;
  height: 373px;
  border-radius: 10px;
  background: var(--ups-brand-colors-ups-teal-light, #F8FBFB);
  position: relative;
}
@media screen and (min-width: 48rem) {
  .subscription-layout .subscription-content .subscriber-panel-container .subscriber {
    flex: 1;
    min-width: 300px;
  }
}
.subscription-layout .subscription-content .subscriber-panel-container .subscriber p {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  margin: 21px 32px 21px 32px;
}
.subscription-layout .subscription-content .subscriber-panel-container .subscriber .current-plan-container,
.subscription-layout .subscription-content .subscriber-panel-container .subscriber .monthly-shipments-container {
  display: flex;
  border-radius: 7px;
  border: 3px solid #004791;
  background: white;
  width: 85%;
  margin: auto;
}
.subscription-layout .subscription-content .subscriber-panel-container .subscriber .current-plan-container .radio-icon,
.subscription-layout .subscription-content .subscriber-panel-container .subscriber .monthly-shipments-container .radio-icon {
  vertical-align: middle;
  border-style: none;
  margin-left: 10px;
  filter: invert(0) sepia(0) saturate(1) hue-rotate(45deg);
}
.subscription-layout .subscription-content .subscriber-panel-container .subscriber .current-plan-container .current-plan-info,
.subscription-layout .subscription-content .subscriber-panel-container .subscriber .monthly-shipments-container .current-plan-info {
  margin-left: 0.7em;
  width: 100%;
}
.subscription-layout .subscription-content .subscriber-panel-container .subscriber .current-plan-container .current-plan-info .current-plan-message,
.subscription-layout .subscription-content .subscriber-panel-container .subscriber .monthly-shipments-container .current-plan-info .current-plan-message {
  margin-top: 10px;
  color: var(--ups-brand-colors-ups-black, #121212);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.subscription-layout .subscription-content .subscriber-panel-container .subscriber .current-plan-container .current-plan-info p,
.subscription-layout .subscription-content .subscriber-panel-container .subscriber .monthly-shipments-container .current-plan-info p {
  margin: 10px 0px 0px 0px;
  color: var(--ups-brand-colors-ups-black, #121212);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.subscription-layout .subscription-content .subscriber-panel-container .subscriber .current-plan-container .current-plan-info .renewal-container,
.subscription-layout .subscription-content .subscriber-panel-container .subscriber .monthly-shipments-container .current-plan-info .renewal-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media screen and (min-width: 48rem) {
  .subscription-layout .subscription-content .subscriber-panel-container .subscriber .current-plan-container .current-plan-info .renewal-container,
  .subscription-layout .subscription-content .subscriber-panel-container .subscriber .monthly-shipments-container .current-plan-info .renewal-container {
    flex-direction: row;
  }
}
.subscription-layout .subscription-content .subscriber-panel-container .subscriber .current-plan-container .current-plan-info .renewal-container .renew-date,
.subscription-layout .subscription-content .subscriber-panel-container .subscriber .monthly-shipments-container .current-plan-info .renewal-container .renew-date {
  color: var(--ups-brand-colors-ups-grey-1, #5F5753);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin: 10px 0px;
}
@media screen and (min-width: 48rem) {
  .subscription-layout .subscription-content .subscriber-panel-container .subscriber .current-plan-container .current-plan-info .renewal-container .renew-date,
  .subscription-layout .subscription-content .subscriber-panel-container .subscriber .monthly-shipments-container .current-plan-info .renewal-container .renew-date {
    margin: 10px 15px;
  }
}
.subscription-layout .subscription-content .subscriber-panel-container .subscriber .monthly-shipments-container {
  height: 140px;
  flex-direction: column;
}
.subscription-layout .subscription-content .subscriber-panel-container .subscriber .monthly-shipments-container p {
  color: #000;
  font-family: "Roboto Flex", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 16px;
}
.subscription-layout .subscription-content .subscriber-panel-container .subscriber .monthly-shipments-container .monthly-shipments-text {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 50%;
}
.subscription-layout .subscription-content .subscriber-panel-container .subscriber .monthly-shipments-container .monthly-shipments-text .monthly\=shipments-title {
  padding: 0 1rem;
}
.subscription-layout .subscription-content .subscriber-panel-container .subscriber .monthly-shipments-container .monthly-shipments-text .info {
  width: 24px;
  height: 24px;
  vertical-align: unset;
  margin-left: -1em;
}
.subscription-layout .subscription-content .subscriber-panel-container .subscriber .monthly-shipments-container .learn-more-container p {
  font-family: "Roboto Flex", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 0px;
}
@media screen and (min-width: 48rem) {
  .subscription-layout .subscription-content .subscriber-panel-container .subscriber .monthly-shipments-container .learn-more-container p {
    margin-top: 10px;
  }
}
.subscription-layout .subscription-content .subscriber-panel-container .subscriber .monthly-shipments-container .learn-more-container .upgrade-msg {
  color: #000;
  margin-bottom: 0px;
}
.subscription-layout .subscription-content .subscriber-panel-container .subscriber .monthly-shipments-container .learn-more-container .learn-more-msg {
  color: var(--ups-brand-colors-ups-blue, #0662BB);
  text-decoration-line: underline;
  cursor: pointer;
  margin-bottom: 10px;
}
.subscription-layout .subscription-content .subscriber-panel-container .subscriber .green-footer-of-container {
  border-radius: 0px 0px 10px 10px;
  background: #4d7eb2;
  height: 117px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: auto;
}
.subscription-layout .subscription-content .gauge-container {
  --gauge-width: 21.388rem;
  --bullet-size: 0.563rem;
  --active-color: #0A8080;
  --inactive-color: #DFDBD7;
  display: block;
  width: var(--gauge-width);
  height: auto;
  margin-top: 2.35em;
  font-family: monospace;
  max-width: 90%;
  height: auto;
  margin: auto;
  margin-top: 0;
}
@media screen and (min-width: 48rem) {
  .subscription-layout .subscription-content .gauge-container {
    width: 90%;
  }
}
.subscription-layout .subscription-content .gauge-container .gauge-wrapper {
  display: block;
  width: 0;
  height: var(--bullet-size);
  background-color: var(--inactive-color);
  border-radius: 9999px;
  position: relative;
  transition: width 1s ease-in-out;
}
.subscription-layout .subscription-content .gauge-container .gauge-wrapper .gauge-progress {
  display: block;
  width: 0;
  height: 100%;
  background-color: var(--active-color);
  border-radius: 9999px;
  position: absolute;
  top: -1px;
  left: 0;
  transition: width 1.5s ease-in-out;
}
.subscription-layout .subscription-content .gauge-container .gauge-wrapper .gauge-progress::after {
  counter-reset: variable var(--label);
  content: counter(variable);
  color: white;
  position: absolute;
  top: -7px;
  right: calc(var(--bullet-size) / 2);
  font-size: calc(var(--bullet-size) / 1.25);
}
.subscription-layout .subscription-content .gauge-container ul.milestones {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
}
.subscription-layout .subscription-content .gauge-container ul.milestones li.milestone {
  display: block;
  width: 0.938rem;
  height: 0.938rem;
  border: solid calc(var(--bullet-size) / 2) var(--inactive-color);
  border-radius: 50%;
  background-color: white;
  position: absolute;
  top: calc(50% - var(--bullet-size) / 2);
  left: 0;
  transition: left 0.5s ease-in-out, border-color 1.7s ease-in-out;
}
.subscription-layout .subscription-content .gauge-container ul.milestones li.milestone::after {
  content: var(--label);
  position: absolute;
  top: 0;
  left: 0;
  font-size: calc(var(--bullet-size) / 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 1em;
  font-family: "Roboto Flex", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  visibility: hidden;
  opacity: 0;
  transition:
    top 1s ease-in-out,
    visibility 1.5s ease-in-out,
    opacity 1s ease-in-out;
}
.subscription-layout .subscription-content .gauge-container.animated .gauge-wrapper {
  width: 90%;
  margin: 5px auto;
}
@media screen and (min-width: 48rem) {
  .subscription-layout .subscription-content .gauge-container.animated .gauge-wrapper {
    width: 100%;
    margin: 0 auto;
  }
}
.subscription-layout .subscription-content .gauge-container.animated .gauge-wrapper .gauge-progress {
  width: calc(var(--percent) * 1%);
}
.subscription-layout .subscription-content .gauge-container.animated .gauge-wrapper ul.milestones li.milestone {
  left: calc(var(--percent) * 1% - var(--bullet-size) / 2);
}
.subscription-layout .subscription-content .gauge-container.animated .gauge-wrapper ul.milestones li.milestone::after {
  top: -19px;
  visibility: visible;
  opacity: 1;
}
.subscription-layout .subscription-content .gauge-container.animated .gauge-wrapper ul.milestones li.milestone.active {
  border-color: #004791;
}
.subscription-layout .subscription-content .feature-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 35px;
  width: 100%;
}
.subscription-layout .subscription-content .feature-panel .header {
  width: 100%;
  display: flex;
  margin-top: 35px;
  flex-direction: column;
  justify-content: center;
  color: #000;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px;
}
.subscription-layout .subscription-content .feature-panel .checkbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
}
@media screen and (min-width: 48rem) {
  .subscription-layout .subscription-content .feature-panel .checkbox {
    flex-direction: row;
  }
}
.subscription-layout .subscription-content .feature-panel .checkbox .section-panel1 {
  width: 100%;
  float: left;
}
.subscription-layout .subscription-content .feature-panel .checkbox .section-panel2 {
  width: 100%;
  float: right;
}
.subscription-layout .subscription-content .feature-panel .checkbox .checkmark_1 {
  margin-right: 10px;
}
.subscription-layout .subscription-content .feature-panel .checkbox .section {
  display: flex;
}
.subscription-layout .subscription-content .feature-panel .checkbox .section p {
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.subscription-layout .subscription-content .linksSection {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.subscription-layout .subscription-content .linksSection .links {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
@media screen and (min-width: 64rem) {
  .subscription-layout .subscription-content .linksSection .links {
    flex-direction: row;
  }
}
.subscription-layout .subscription-content .linksSection .links .link {
  width: 100%;
  height: 35%;
  margin-bottom: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #0662BB;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
@media screen and (min-width: 48rem) {
  .subscription-layout .subscription-content .linksSection .links .link {
    border-right: solid thin #0662BB;
  }
  .subscription-layout .subscription-content .linksSection .links .link:first-child {
    border-left: solid thin #0662BB;
  }
}
.subscription-layout .subscription-content .linksSection .links .disabled {
  color: #AFB1BF;
}
.subscription-layout .subscription-content .linksSection .links .cancelSubscription {
  border-left: none;
}
.subscription-layout .subscription-content .dropdown {
  width: 1120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 21.5rem) {
  .subscription-layout .subscription-content .dropdown {
    max-width: 32% !important;
    margin-left: 0.15em !important;
  }
}
@media only screen and (max-width: 32.6em) {
  .subscription-layout .subscription-content .dropdown {
    max-width: 35%;
    align-items: flex-start;
  }
}
.subscription-layout .subscription-content .dropdown .header-dropdown {
  width: 800px;
  flex-shrink: 0;
  border-top: 1px solid var(--ups-brand-colors-ups-grey-2, #8C857E);
  border-bottom: 1px solid var(--ups-brand-colors-ups-grey-2, #8C857E);
  background: #FFF;
}
@media only screen and (max-width: 21.5rem) {
  .subscription-layout .subscription-content .dropdown .header-dropdown {
    max-width: 100% !important;
    margin-left: 0.15em !important;
  }
}
@media only screen and (max-width: 32.6em) {
  .subscription-layout .subscription-content .dropdown .header-dropdown {
    max-width: 122%;
    margin-left: 0em;
  }
}
.subscription-layout .subscription-content .dropdown .header-dropdown .ddSection {
  width: 800px;
  height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media only screen and (max-width: 21.5rem) {
  .subscription-layout .subscription-content .dropdown .header-dropdown .ddSection {
    max-width: 100% !important;
    margin-left: 0.15em !important;
  }
}
@media only screen and (max-width: 32.6em) {
  .subscription-layout .subscription-content .dropdown .header-dropdown .ddSection {
    max-width: 100%;
    margin-left: 0em;
  }
}
.subscription-layout .subscription-content .dropdown .header-dropdown .ddSection .expand_more {
  width: 12px;
  height: 7px;
  flex-shrink: 0;
  cursor: pointer;
}
.subscription-layout .subscription-content .dropdown .header-dropdown .ddSection h1 {
  width: 259px;
  height: 21px;
  float: left;
  flex-shrink: 0;
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-left: 2em;
}
.subscription-layout .subscription-content .dropdown .header-dropdown .tablePanel {
  margin-top: 0.5em;
  color: var(--ups-brand-colors-ups-black, #121212);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-style: normal;
}
@media only screen and (max-width: 32.6em) {
  .subscription-layout .subscription-content .dropdown .header-dropdown .tablePanel {
    overflow: scroll;
  }
}
.subscription-layout .subscription-content .dropdown .header-dropdown .tablePanel table.my-table {
  border: none;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
}
.subscription-layout .subscription-content .dropdown .header-dropdown .tablePanel thead.my-table.headers tr th {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  width: 124px;
  height: 56px;
}
.subscription-layout .subscription-content .dropdown .header-dropdown .tablePanel thead.my-table.headers tr th .sideHeader {
  text-wrap: nowrap;
}
.subscription-layout .subscription-content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.background1 {
  background: var(--ups-brand-colors-ups-teal-light, #F8FBFB);
}
.subscription-layout .subscription-content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.background2 {
  background: var(--semantic-colors-ups-feature-teal-light, #E7F2F2);
}
.subscription-layout .subscription-content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.background3 {
  background: var(--ups-brand-colors-ups-teal-medium, #C2E3E2);
}
.subscription-layout .subscription-content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.background4 {
  background: rgba(0, 154, 144, 0.38);
}
.subscription-layout .subscription-content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border-bottom: 1px solid var(--ups-brand-colors-ups-grey-3, #BFB8AF);
}
.subscription-layout .subscription-content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.sideHeader {
  display: flex;
  width: 180px;
  height: 70px;
  padding: 24px 0px 24px 10px;
  align-items: center;
  text-align: left;
  border-bottom: 1px solid var(--ups-brand-colors-ups-grey-3, #BFB8AF);
  background: var(--ups-brand-colors-ups-grey-5, #F2F1EF);
}
@media only screen and (max-width: 32.6em) {
  .subscription-layout .subscription-content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.sideHeader {
    height: 84px;
  }
}
.subscription-layout .subscription-content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.sideHeader sup {
  font-family: "Roboto Flex", sans-serif;
  font-size: 9px;
}
.subscription-layout .subscription-content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.accessLabel {
  padding: 0px 0px 0px 0px !important;
  display: flex;
  flex-direction: column;
}
.subscription-layout .subscription-content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.accessLabel .accessUPS {
  font-size: 14px;
  display: flex;
  width: 180px;
  height: 70px;
  padding: 0px 0px 0px 11px;
  align-items: flex-end;
  text-align: left;
  background: var(--ups-brand-colors-ups-grey-5, #F2F1EF);
}
@media only screen and (max-width: 32.6em) {
  .subscription-layout .subscription-content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.accessLabel .accessUPS {
    height: 84px;
  }
}
.subscription-layout .subscription-content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.accessLabel .accessUPS .rated {
  top: -7px;
  font-family: "Roboto Flex", sans-serif;
  font-size: 9px;
  position: relative;
}
.subscription-layout .subscription-content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.accessLabel .accessPoint {
  display: flex;
  width: 180px;
  height: 70px;
  padding: 0px 0px 0px 11px;
  align-items: flex-start;
  text-align: left;
  border-bottom: 1px solid var(--ups-brand-colors-ups-grey-3, #BFB8AF);
  background: var(--ups-brand-colors-ups-grey-5, #F2F1EF);
}
@media only screen and (max-width: 32.6em) {
  .subscription-layout .subscription-content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.accessLabel {
    height: 84px;
  }
}
.subscription-layout .subscription-content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.accessLabel .rated {
  font-family: "Roboto Flex", sans-serif;
  font-size: 9px;
}
.subscription-layout .subscription-content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.label {
  flex-direction: column;
  align-items: flex-start;
}
.subscription-layout .subscription-content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.label .comingSoon {
  width: 70px;
  height: 15px;
  margin-top: -1.5em;
  margin-bottom: 0.8em;
  flex-shrink: 0;
  border-radius: 4px;
  color: var(--ups-brand-colors-ups-white, #FFF);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  line-height: 15px;
  background: var(--ups-brand-colors-ups-teal, #0A8080);
}
.subscription-layout .subscription-content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.dot {
  width: 124px;
  height: 56px;
  padding: 24px 0px;
  font-size: 40px;
  font-style: normal;
  font-weight: 400;
}
.subscription-layout .subscription-content .dropdown .header-dropdown .tablePanel tbody.my-table.body tr td.last {
  border-right: none;
}
.subscription-layout .subscription-content .dropdown .header-dropdown .tablePanel h2 {
  color: #000;
  font-family: "Roboto Flex", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-top: 7px;
}
.subscription-layout .subscription-content .dropdown .header-dropdown .tablePanel h2 a {
  color: var(--ups-brand-colors-ups-blue, #0662BB);
  font-family: "Roboto Flex", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-decoration-line: underline;
}
.subscription-layout .auto-Renewel {
  width: 100%;
}
@media screen and (min-width: 48rem) {
  .subscription-layout .auto-Renewel {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: -200px;
    align-items: center;
    padding-left: 10px;
    width: 200px;
    margin-top: 15px;
  }
}
.subscription-layout .auto-Renewel .dropdown {
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 88%;
  padding: 13px 11px 10px 10px;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #000;
  background: #FFF;
}
.subscription-layout .auto-Renewel .dropdown .auto-renewal-subscription-text {
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 100%;
  height: 100%;
  margin-bottom: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.subscription-layout .auto-Renewel .dropdown .ddown {
  cursor: pointer;
}
.subscription-layout .auto-Renewel .toggleBtn {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #FFF;
}
.subscription-layout .auto-Renewel .toggleBtn .togglePanel {
  background: #FFF;
  width: 100%;
  height: 63px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 9px 23px;
  border-bottom: 1px solid var(--ups-brand-colors-ups-grey-3, #BFB8AF);
}
.subscription-layout .auto-Renewel .toggleBtn .custom-control-input:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.subscription-layout .auto-Renewel .toggleBtn .on-text {
  color: var(--ups-brand-colors-ups-black, #121212);
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  float: left;
  margin-top: 8px;
}
.subscription-layout .auto-Renewel .toggleBtn .custom-control {
  width: 100px;
  height: 40px;
  display: inline-flex;
  margin-left: 15px;
}
.subscription-layout .auto-Renewel .toggleBtn .custom-control-label {
  padding-bottom: 2.5rem;
}
.subscription-layout .auto-Renewel .toggleBtn .custom-control-label::before {
  height: 31px;
  width: calc(3.4rem + 0.75rem);
  border-radius: 5rem;
}
.subscription-layout .auto-Renewel .toggleBtn .custom-control-label::after {
  width: 24px;
  height: 24px;
  border-radius: calc(3.75rem - 12px);
  top: 8px;
}
.subscription-layout .auto-Renewel .toggleBtn .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #004791;
  background-color: #004791;
}
.subscription-layout .auto-Renewel .toggleBtn .custom-control-input:checked ~ .custom-control-label::after {
  transform: translateX(calc(2.5rem - 0.25rem));
}
.subscription-layout .auto-Renewel .section {
  background: #FFF;
  width: 100%;
  padding: 9px 17px;
}
.subscription-layout .auto-Renewel .section .panel1 {
  display: inline-flex;
  align-items: center;
  gap: 12px;
}
.subscription-layout .auto-Renewel .section .panel1 .checked {
  width: 25px;
  height: 25px;
  filter: invert(0) sepia(0) saturate(1) hue-rotate(45deg);
}
.subscription-layout .auto-Renewel .section .panel1 .renewal-reminder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #000;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0px;
  margin-top: 0;
}
.subscription-layout .auto-Renewel .section p {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #000;
  font-family: Inter;
  font-size: 9px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.subscription-layout .layout-icons {
  display: none;
  position: fixed;
  bottom: 80px;
  right: 18px;
}
@media screen and (min-width: 64rem) {
  .subscription-layout .layout-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 19.36px;
  }
}
@media only screen and (min-width: 64rem) and (max-width: 21.5rem) {
  .subscription-layout .layout-icons {
    margin-left: 9px !important;
  }
}
@media only screen and (min-width: 64rem) and (max-width: 32.6em) {
  .subscription-layout .layout-icons {
    width: 40%;
    margin-left: 1em;
    margin-top: -57em;
  }
}
@media screen and (min-width: 64rem) {
  .subscription-layout .layout-icons .label {
    display: flex;
    width: 72.6px;
    height: 25.41px;
    align-items: center;
    gap: 12.1px;
    border-radius: 4.84px;
    background: #FFF;
    box-shadow: 0px 4.84001px 4.84001px 0px rgba(0, 0, 0, 0.25);
  }
}
@media only screen and (min-width: 64rem) and (max-width: 32.6em) {
  .subscription-layout .layout-icons .label {
    width: 57.6px;
    height: 23.41px;
  }
}
@media screen and (min-width: 64rem) {
  .subscription-layout .layout-icons .label .ship-with-text {
    margin-top: 9px;
    display: flex;
    width: 72.6px;
    height: 20.57px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Roboto Flex", sans-serif;
    font-size: 12.1px;
    font-style: normal;
    font-weight: 500;
    line-height: 19.36px;
  }
}
@media only screen and (min-width: 64rem) and (max-width: 32.6em) {
  .subscription-layout .layout-icons .label .ship-with-text {
    width: 57.6px;
    font-size: 11.1px;
  }
}
@media screen and (min-width: 64rem) {
  .subscription-layout .layout-icons .create-ups-label-container .label,
  .subscription-layout .layout-icons .create-fedex-label-container .label {
    display: none;
    width: 100%;
    height: 25.41px;
    align-items: center;
    gap: 12.1px;
    padding-top: 5px;
    border-radius: 4.84px;
    background: #FFF;
    box-shadow: 0px 4.84001px 4.84001px 0px rgba(0, 0, 0, 0.25);
    min-width: 110px;
  }
}
@media only screen and (min-width: 64rem) and (max-width: 32.6em) {
  .subscription-layout .layout-icons .create-ups-label-container .label,
  .subscription-layout .layout-icons .create-fedex-label-container .label {
    width: 57.6px;
    height: 23.41px;
  }
}
@media screen and (min-width: 64rem) {
  .subscription-layout .layout-icons .create-ups-label-container .label .create-ups-label,
  .subscription-layout .layout-icons .create-ups-label-container .label .create-fedex-label,
  .subscription-layout .layout-icons .create-fedex-label-container .label .create-ups-label,
  .subscription-layout .layout-icons .create-fedex-label-container .label .create-fedex-label {
    margin-top: 9px;
    display: flex;
    width: 100%;
    height: 20.57px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;
    font-family: "Roboto Flex", sans-serif;
    font-size: 12.1px;
    font-style: normal;
    font-weight: 500;
    line-height: 19.36px;
  }
}
@media only screen and (min-width: 64rem) and (max-width: 32.6em) {
  .subscription-layout .layout-icons .create-ups-label-container .label .create-ups-label,
  .subscription-layout .layout-icons .create-ups-label-container .label .create-fedex-label,
  .subscription-layout .layout-icons .create-fedex-label-container .label .create-ups-label,
  .subscription-layout .layout-icons .create-fedex-label-container .label .create-fedex-label {
    width: 57.6px;
    font-size: 11.1px;
  }
}
@media screen and (min-width: 64rem) {
  .subscription-layout .layout-icons .create-ups-label-container .ship:hover + .label,
  .subscription-layout .layout-icons .create-ups-label-container .fedex:hover + .label,
  .subscription-layout .layout-icons .create-fedex-label-container .ship:hover + .label,
  .subscription-layout .layout-icons .create-fedex-label-container .fedex:hover + .label {
    display: flex;
  }
  .subscription-layout .layout-icons .create-ups-label-container .ship,
  .subscription-layout .layout-icons .create-ups-label-container .fedex,
  .subscription-layout .layout-icons .create-fedex-label-container .ship,
  .subscription-layout .layout-icons .create-fedex-label-container .fedex {
    cursor: pointer;
    width: 60.5px;
    height: 60.5px;
    border-radius: 50%;
    background-color: white;
    box-shadow: 0px 4.84001px 4.84001px 0px rgba(0, 0, 0, 0.25);
  }
}
@media only screen and (min-width: 64rem) and (max-width: 32.6em) {
  .subscription-layout .layout-icons .create-ups-label-container .ship,
  .subscription-layout .layout-icons .create-ups-label-container .fedex,
  .subscription-layout .layout-icons .create-fedex-label-container .ship,
  .subscription-layout .layout-icons .create-fedex-label-container .fedex {
    width: 50.5px;
    height: 50.5px;
  }
}
@media screen and (min-width: 64rem) {
  .subscription-layout .layout-icons .create-ups-label-container .ship .ups_Flat_Shield,
  .subscription-layout .layout-icons .create-ups-label-container .fedex .ups_Flat_Shield,
  .subscription-layout .layout-icons .create-fedex-label-container .ship .ups_Flat_Shield,
  .subscription-layout .layout-icons .create-fedex-label-container .fedex .ups_Flat_Shield {
    width: 30.501px;
    height: 36.3px;
    flex-shrink: 0;
    margin-left: 15px;
    margin-top: 14px;
  }
}
@media only screen and (min-width: 64rem) and (max-width: 32.6em) {
  .subscription-layout .layout-icons .create-ups-label-container .ship .ups_Flat_Shield,
  .subscription-layout .layout-icons .create-ups-label-container .fedex .ups_Flat_Shield,
  .subscription-layout .layout-icons .create-fedex-label-container .ship .ups_Flat_Shield,
  .subscription-layout .layout-icons .create-fedex-label-container .fedex .ups_Flat_Shield {
    width: 28.501px;
    height: 33.3px;
    margin-left: 11px;
    margin-top: 10px;
  }
}
@media screen and (min-width: 64rem) {
  .subscription-layout .layout-icons .create-ups-label-container .ship .fedex_express,
  .subscription-layout .layout-icons .create-ups-label-container .fedex .fedex_express,
  .subscription-layout .layout-icons .create-fedex-label-container .ship .fedex_express,
  .subscription-layout .layout-icons .create-fedex-label-container .fedex .fedex_express {
    width: 48.4px;
    height: 13.417px;
    margin-left: 10px;
    margin-top: 23px;
  }
}
@media only screen and (min-width: 64rem) and (max-width: 32.6em) {
  .subscription-layout .layout-icons .create-ups-label-container .ship .fedex_express,
  .subscription-layout .layout-icons .create-ups-label-container .fedex .fedex_express,
  .subscription-layout .layout-icons .create-fedex-label-container .ship .fedex_express,
  .subscription-layout .layout-icons .create-fedex-label-container .fedex .fedex_express {
    width: 44.4px;
    height: 11.417px;
    margin-left: 7px;
    margin-top: 20px;
  }
}
.subscription-layout .create-carrier-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}
.subscription-layout .create-carrier-label .label {
  position: absolute;
  left: -127px;
}
.section .section-title {
  font-size: 1.875rem;
  width: 100%;
  text-align: center;
  padding: 1em 0;
}
@media screen and (min-width: 48rem) {
  .section .section-title {
    font-size: 2.5rem;
  }
}
ul.features {
  margin: 0;
  padding: 0 3rem;
  list-style-type: none;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  flex-wrap: wrap;
}
ul.features li.feature {
  color: rgba(36, 36, 36, 0.87);
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  margin-bottom: 1rem;
  line-height: 1.5;
}
ul.features li.feature::before {
  content: url(/assets/icons/checkmark_1.svg);
  display: inline-block;
  width: 25px;
  height: 25px;
  margin-right: 1rem;
  filter: invert(0) sepia(0) saturate(1) hue-rotate(45deg);
}
@media screen and (min-width: 48rem) {
  ul.features li.feature {
    width: 50%;
  }
}
ul.features li.feature sup {
  line-height: 1;
}
.custom-control {
  width: 100px;
  height: 40px;
  display: inline-flex;
  margin-left: 15px;
  position: relative;
  z-index: 1;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  -webkit-print-color-adjust: exact;
}
.custom-control.custom-switch {
  padding-left: 2.25rem;
}
.custom-control.custom-switch .input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}
.custom-control.custom-switch .custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control.custom-switch .custom-control-label {
  display: inline-block;
  padding-bottom: 2.5rem;
  color: #6c757d;
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control.custom-switch .custom-control-label::before {
  content: "";
  color: #fff;
  background-color: #004791;
  height: 31px;
  width: 4.15rem;
  border-radius: 5rem;
  position: absolute;
  top: 0.25rem;
  left: -2.25rem;
  transition:
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  border: 1px solid #adb5bd;
}
.custom-control.custom-switch .custom-control-label::after {
  content: "";
  transform: translate(2.25rem);
  display: block;
  position: absolute;
  transition:
    transform 0.15s ease-in-out,
    background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  background: #fff no-repeat;
  width: 24px;
  height: 24px;
  border-radius: calc(3.75rem - 12px);
  top: 8px;
  left: calc(-2.25rem + 2px);
}
/*# sourceMappingURL=dd-manage-subscription.component.css.map */
