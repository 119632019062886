/* src/app/delivery-defense/dd-address-confidence-tool/dd-upload-address-file/dd-upload-address-popups/dd-upload-address-popups.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 21.5rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.main-layout {
  margin: 32px;
}
.main-layout header {
  position: relative;
}
.main-layout header h2 {
  color: var(--UPS-Brand-Colors-UPS-Black, #121212);
  font-family: Roboto;
  font-size: 30px;
  font-weight: 400;
  margin-bottom: 16px;
}
@media screen and (min-width: 48rem) {
  .main-layout header h2 {
    font-size: 40px;
  }
}
.main-layout header .divider {
  width: 72px;
  height: 4px;
  background: var(--Capital-Color-Libary-UPSC-Teal, #00857D);
  margin-top: 26px;
}
.main-layout .body-container p {
  color: var(--UPS-Brand-Colors-UPS-Black, #121212);
  font-family: Roboto;
  font-size: 15px;
  font-weight: 400;
}
@media screen and (min-width: 48rem) {
  .main-layout .body-container p {
    font-size: 20px;
  }
}
.main-layout .body-container .cancel-or-submit-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 1rem;
}
.main-layout .body-container .cancel-or-submit-container .mdc-button {
  font-size: 0.75rem;
  border-radius: 9999px;
}
/*# sourceMappingURL=dd-upload-address-popups.component.css.map */
