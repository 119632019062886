import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { decode } from 'he';
import { map, Observable } from 'rxjs';

@Injectable()
export class HtmlDecoderInterceptor implements HttpInterceptor {
    public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(req).pipe(
            map(event => {
                if (event instanceof HttpResponse && event.body) {
                    return event.clone({ body: this.decodeEntities(event.body) });
                }

                return event;
            }),
        );
    }

    private decodeEntities(data: unknown): unknown {
        if (typeof data === 'string') {
            return decode(data); // Decode encoded characters in strings
        } else if (Array.isArray(data)) {
            return data.map(item => this.decodeEntities(item)); // Recursively decode arrays
        } else if (typeof data === 'object' && data !== null) {
            Object.keys(data).forEach(key => {
                data[key] = this.decodeEntities(data[key]); // Recursively decode objects
            });
        }

        return data;
    }
}
