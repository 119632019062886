/* src/app/delivery-defense/dd-address-confidence-tool/dd-upload-address-file/dd-upload-address-file.component.scss */
.layout {
  width: 100%;
  font-family: Roboto;
}
.layout .content {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-shrink: 0;
  background: #FFF;
}
@media screen and (min-width: 75em) {
  .layout .content {
    width: 1150px;
    margin: 0 auto;
    position: relative;
    padding: 0px 90px 19px 90px;
  }
}
@media screen and (min-width: 25rem) and (max-width: 80rem) and (min-height: 25rem) and (max-height: 45rem) {
  .layout .content {
    padding: 0px 50px 0px 50px;
    width: 980px;
  }
}
.layout .content .main-container {
  padding: 0 25px 15px 25px;
  margin-top: 25px;
}
.layout .content .main-container .header-container {
  display: flex;
  justify-content: space-between;
}
.layout .content .main-container .header-container h2 {
  color: #000;
  font-size: 14.918px;
  font-style: normal;
  font-weight: 500;
  line-height: 17.901px;
}
.layout .content .main-container .header-container .download-template-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.layout .content .main-container .header-container .download-template-container .download-icon {
  width: 13.128px;
  height: 13.128px;
  margin-left: 10px;
  position: absolute;
  left: 8.1rem;
  filter: invert(0) sepia(0) saturate(1) hue-rotate(45deg);
}
.layout .content .main-container .header-container .download-template-container ::ng-deep .mat-mdc-raised-button:not([class*=mat-elevation-z]):not([disabled]):not(.disabled).transparent-button {
  border: 1.492px solid #004791;
  color: #004791;
  font-size: 11.934px;
  font-weight: 500;
  border-radius: 3px;
  height: 32px;
  padding: 7.46px 30px 7.46px 17.9px;
}
.layout .content .main-container p {
  color: var(--UPS-Brand-Colors-UPS-Black, #121212);
  font-style: normal;
  font-weight: 400;
}
.layout .content .main-container .looking-to-save-time {
  font-size: 11.934px;
  line-height: 20.512px;
  margin-top: 7.459px;
}
.layout .content .main-container .how-to-fill-out {
  font-size: 12.298px;
  line-height: 18.447px;
}
.layout .content .main-container a {
  text-decoration: underline;
  cursor: pointer;
  color: #0662BB;
}
.layout .content .main-container .tips-and-tricks-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 30px;
  flex-direction: column;
}
@media screen and (min-width: 75em) {
  .layout .content .main-container .tips-and-tricks-container {
    flex-direction: row;
  }
}
.layout .content .main-container .tips-and-tricks-container .close-icon-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.layout .content .main-container .tips-and-tricks-container .close-icon-container .close-icon {
  width: 12.298px;
  height: 12.298px;
  cursor: pointer;
}
.layout .content .main-container .tips-and-tricks-container .text-and-icon-container {
  margin-left: 15px;
}
.layout .content .main-container .tips-and-tricks-container .text-and-icon-container h3 {
  color: var(--UPS-Brand-Colors-UPS-Black, #121212);
  font-size: 10.761px;
  font-weight: 600;
  text-align: center;
}
.layout .content .main-container .tips-and-tricks-container .text-and-icon-container .thumbs-icon {
  width: 38.432px;
  height: 38.432px;
  margin: 0 auto;
  display: flex;
}
.layout .content .main-container .tips-and-tricks-container .file-container {
  min-width: 188px;
  position: relative;
  margin-bottom: 50px;
}
@media screen and (min-width: 75em) {
  .layout .content .main-container .tips-and-tricks-container .file-container {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 25rem) and (max-width: 80rem) and (min-height: 25rem) and (max-height: 45rem) {
  .layout .content .main-container .tips-and-tricks-container .file-container {
    min-width: 160px;
  }
}
@media screen and (min-width: 75em) {
  .layout .content .main-container .tips-and-tricks-container .file-container .divider {
    width: 2.306px;
    height: 77.633px;
    background: #D8D7DF;
    margin-top: 22px;
    position: absolute;
    left: 13rem;
  }
}
@media screen and (min-width: 25rem) and (max-width: 80rem) and (min-height: 25rem) and (max-height: 45rem) {
  .layout .content .main-container .tips-and-tricks-container .file-container .divider {
    left: 10rem;
  }
}
.layout .content .main-container .tips-and-tricks-container .file-container .number-and-icon-container {
  display: flex;
  justify-content: space-between;
}
.layout .content .main-container .tips-and-tricks-container .file-container .number-and-icon-container span {
  color: var(--Capital-Color-Libary-UPSC-Teal, #00857D);
  text-align: center;
  font-family: Inter;
  font-size: 27.671px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}
.layout .content .main-container .tips-and-tricks-container .file-container .number-and-icon-container .img-wrapper {
  margin: 0 auto;
}
@media screen and (min-width: 75em) {
  .layout .content .main-container .tips-and-tricks-container .first {
    margin-left: 10px;
  }
}
@media screen and (min-width: 75em) {
  .layout .content .main-container .tips-and-tricks-container .last {
    margin-right: 17px;
  }
}
.layout .content .main-container .scoringInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
@media only screen and (max-width: 21.5rem) {
  .layout .content .main-container .scoringInfo {
    max-width: 100% !important;
  }
}
@media only screen and (max-width: 32.6em) {
  .layout .content .main-container .scoringInfo {
    width: 100%;
  }
}
.layout .content .main-container .scoringInfo h1 {
  cursor: pointer;
  color: #397AB7;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto Flex, sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 44.8px;
}
@media only screen and (max-width: 32.6em) {
  .layout .content .main-container .scoringInfo h1 {
    font-size: 25px;
  }
}
@media only screen and (max-width: 21.5rem) {
  .layout .content .main-container .scoringInfo h1 {
    font-size: 23px;
  }
}
.layout .content .main-container .scoringInfo .scoringInfoPanel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}
@media only screen and (max-width: 32.6em) {
  .layout .content .main-container .scoringInfo .scoringInfoPanel {
    overflow: scroll;
    width: 99%;
    margin-left: 0.5em;
    padding: 10px;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
@media only screen and (max-width: 21.5rem) {
  .layout .content .main-container .scoringInfo .scoringInfoPanel {
    max-width: 100% !important;
    margin-left: 0.15em !important;
  }
}
.layout .content .main-container .scoringInfo .scoringInfoPanel .sliderScale {
  width: 799.342px;
  height: 16.447px;
  flex-shrink: 0;
  border-radius: 6.579px;
  background:
    linear-gradient(
      90deg,
      #F66E6E 0%,
      #F6AC68 25%,
      #F1D367 49.48%,
      #E1F46D 72.4%,
      #97F26C 100%);
  box-shadow: 0px 4px 12px -3px rgba(0, 0, 0, 0.05), 0px 2px 5px 0px rgba(0, 0, 0, 0.14);
}
.layout .content .main-container .scoringInfo .scoringInfoPanel .infoTable {
  width: 799.342px;
}
.layout .content .main-container .scoringInfo .scoringInfoPanel .infoTable .labels {
  width: 799.342px;
}
.layout .content .main-container .scoringInfo .scoringInfoPanel .infoTable .labels .lowConfidence,
.layout .content .main-container .scoringInfo .scoringInfoPanel .infoTable .labels .highconfidence {
  color: var(--ups-brand-colors-ups-black, #121212);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto Flex, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
@media only screen and (max-width: 21.5rem) {
  .layout .content .main-container .scoringInfo .scoringInfoPanel .infoTable .labels .lowConfidence,
  .layout .content .main-container .scoringInfo .scoringInfoPanel .infoTable .labels .highconfidence {
    font-size: 18px !important;
  }
}
.layout .content .main-container .scoringInfo .scoringInfoPanel .infoTable .labels .lowConfidence {
  float: left;
}
.layout .content .main-container .scoringInfo .scoringInfoPanel .infoTable .labels .highconfidence {
  float: right;
}
.layout .content .main-container .scoringInfo .scoringInfoPanel .infoTable .labels h2 {
  color: var(--ups-brand-colors-ups-black, #121212);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto Flex, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
@media only screen and (max-width: 21.5rem) {
  .layout .content .main-container .scoringInfo .scoringInfoPanel .infoTable .labels h2 {
    font-size: 14px !important;
  }
}
@media only screen and (max-width: 32.6em) {
  .layout .content .main-container .scoringInfo .scoringInfoPanel .infoTable .labels h2 {
    font-size: 14px !important;
  }
}
.layout .content .main-container .scoringInfo .scoringInfoPanel .infoTable .my-table {
  margin-top: 2em;
  font-style: normal;
  font-feature-settings: "clig" off, "liga" off;
  width: 799.342px;
  font-family: Roboto Flex, sans-serif;
}
.layout .content .main-container .scoringInfo .scoringInfoPanel .infoTable .my-table thead.my-table.headers tr th {
  width: 175.658px;
  height: 36.842px;
  padding: 15.789px 0px;
  gap: 38.158px;
  flex-shrink: 0;
  color: var(--ups-brand-colors-ups-white, #FFF);
  background: rgb(56, 56, 56);
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  border-right: 0.658px solid var(--ups-brand-colors-ups-white, #FFF);
}
.layout .content .main-container .scoringInfo .scoringInfoPanel .infoTable .my-table tbody.my-table.body tr td {
  width: 175.658px;
  padding: 15.789px 0px;
  color: var(--ups-brand-colors-ups-black, #121212);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto Flex, sans-serif;
  font-size: 10.526px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.421px;
}
.layout .content .main-container .scoringInfo .scoringInfoPanel .infoTable .my-table tbody.my-table.body tr td .size {
  color: var(--ups-brand-colors-ups-black, #121212);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto Flex, sans-serif;
  font-size: 13.158px;
  font-style: normal;
  font-weight: 700;
  line-height: 18.421px;
}
.layout .content .main-container .scoringInfo .scoringInfoPanel .infoTable .my-table tbody.my-table.body tr {
  border-bottom: inset;
}
.layout .content .main-container .scoringInfo .scoringInfoPanel .labels {
  width: 799.342px;
}
.layout .content .main-container .scoringInfo .scoringInfoPanel .labels .lowConfidence {
  float: left;
}
.layout .content .main-container .scoringInfo .scoringInfoPanel .labels .highconfidence {
  float: right;
}
.layout .content .main-container .scoringInfo .scoringInfoPanel .labels h2 {
  color: var(--ups-brand-colors-ups-black, #121212);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto Flex, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.layout .content .main-container .click-or-drag-file-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 166.333px;
  padding: 23.868px;
  border-radius: 5.967px;
  border: 1.492px dashed rgba(78, 83, 87, 0.6);
  margin-top: 17.9px;
}
@media screen and (min-width: 75em) {
  .layout .content .main-container .click-or-drag-file-container {
    height: 166.333px;
  }
}
.layout .content .main-container .click-or-drag-file-container .upload-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.layout .content .main-container .click-or-drag-file-container .upload-wrapper .drive-folder-icon {
  width: 44px;
  height: 40px;
}
.layout .content .main-container .click-or-drag-file-container .upload-wrapper p {
  color: #000;
  font-size: 11.934px;
  font-weight: 400;
}
.layout .content .main-container .click-or-drag-file-container .upload-wrapper span {
  color: var(--Capital---Gray-2, #4E5357);
  font-size: 11.934px;
  font-weight: 400;
  line-height: 0;
}
.layout .content .main-container .click-or-drag-file-container .selected-file-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.layout .content .main-container .click-or-drag-file-container .selected-file-container .file-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.layout .content .main-container .click-or-drag-file-container .selected-file-container .file-wrapper .excel-4954 {
  width: 14.918px;
  height: 14.918px;
}
.layout .content .main-container .click-or-drag-file-container .selected-file-container p {
  color: var(--Capital---Gray-2, #4E5357);
  font-size: 11.934px;
  font-weight: 400;
  line-height: 11.934px;
  letter-spacing: 0.06px;
  margin-left: 7.46px;
}
.layout .content .main-container .click-or-drag-file-container .selected-file-container span {
  color: var(--UPS-Brand-Colors-UPS-Teal, #0A8080);
}
.layout .content .main-container .click-or-drag-file-container .selected-file-container .error-message-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.layout .content .main-container .click-or-drag-file-container .selected-file-container .error-message-container .error-icon {
  width: 11.934px;
  height: 11.934px;
  margin-bottom: 19px;
}
@media screen and (min-width: 75em) {
  .layout .content .main-container .click-or-drag-file-container .selected-file-container .error-message-container .error-icon {
    margin-bottom: 13px;
  }
}
.layout .content .main-container .click-or-drag-file-container .selected-file-container .error-message-container .error-message {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--UPS-Brand-Colors-UPS-Error-Red, #DF2901);
  font-size: 8.951px;
  font-weight: 500;
  margin-left: 2.98px;
}
.layout .content .main-container .click-or-drag-file-container .selected-file-container .duplicate-addresses {
  color: var(--Capital---Gray-2, #4E5357);
  margin: 5.97px 0 10px 0;
  font-size: 11.934px;
  font-weight: 400;
  line-height: 11.934px;
  letter-spacing: 0.06px;
}
.layout .content .main-container .click-or-drag-file-container .selected-file-container .btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;
}
.layout .content .main-container .click-or-drag-file-container .selected-file-container .btn-container .mdc-button {
  font-size: 0.75rem;
}
.layout .submission-complete-container .submission-complete-message {
  color: var(--UPS-Brand-Colors-UPS-Black, #121212);
  text-align: center;
  font-family: Roboto;
  font-size: 20.799px;
  font-weight: 400;
  line-height: 26.742px;
}
.layout .submission-complete-container p {
  color: var(--Capital---Gray-2, #4E5357);
  text-align: center;
  font-size: 11.934px;
  font-weight: 400;
  line-height: 11.934px;
}
.layout .submission-complete-container .submission-complete-progress-bar-container {
  height: 2.971px;
  width: 90%;
  background: var(--Capital-Color-Libary-UPSC-Teal, #00857D);
  box-shadow: -0.743px 0px 0px 0px #0A8080;
  position: relative;
  margin: 0 auto;
  margin-bottom: 35px;
  display: flex;
  justify-content: space-between;
}
@media screen and (min-width: 75em) {
  .layout .submission-complete-container .submission-complete-progress-bar-container {
    width: 60%;
  }
}
.layout .submission-complete-container .submission-complete-progress-bar-container .progress-bar-wrapper .indicator-icon {
  width: 8.914px;
  height: 8.914px;
  position: absolute;
  top: -3px;
}
.layout .submission-complete-container .submission-complete-progress-bar-container .progress-bar-wrapper .start-icon {
  left: -5px;
}
.layout .submission-complete-container .submission-complete-progress-bar-container .progress-bar-wrapper .middle-icon {
  left: 50%;
  transform: translateX(-50%);
}
.layout .submission-complete-container .submission-complete-progress-bar-container .progress-bar-wrapper .end-icon {
  right: -5px;
}
.layout .submission-complete-container .submission-complete-progress-bar-container .progress-bar-wrapper span {
  color: var(--UPSC-Gray-1, #4A4A4C);
  text-align: center;
  font-size: 11.885px;
  font-weight: 400;
  line-height: 17.828px;
  position: absolute;
  margin-top: 12px;
}
.layout .submission-complete-container .submission-complete-progress-bar-container .progress-bar-wrapper .start-span {
  left: -35px;
}
.layout .submission-complete-container .submission-complete-progress-bar-container .progress-bar-wrapper .middle-span {
  left: 50%;
  transform: translateX(-50%);
}
.layout .submission-complete-container .submission-complete-progress-bar-container .progress-bar-wrapper .end-span {
  right: -35px;
}
.layout .scored-address-container .message-container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 1.5rem;
  background: #FFF;
}
@media screen and (min-width: 75em) {
  .layout .scored-address-container .message-container {
    width: 1150px;
    padding: 15px 115px 30px 115px;
    margin: 0 auto;
  }
}
@media screen and (min-width: 25rem) and (max-width: 80rem) and (min-height: 25rem) and (max-height: 45rem) {
  .layout .scored-address-container .message-container {
    padding: 0 50px 0 50px;
    width: 980px;
  }
}
.layout .scored-address-container .message-container .processing-records-message {
  color: black;
  font-weight: bold;
  font-size: 16px;
}
.layout .scored-address-container .close-file-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 20px 0 20px 0;
  background: #FFF;
}
@media screen and (min-width: 75em) {
  .layout .scored-address-container .close-file-container {
    width: 1150px;
    padding: 0 90px 60px 90px;
    margin: 0 auto;
    display: block;
  }
}
@media screen and (min-width: 25rem) and (max-width: 80rem) and (min-height: 25rem) and (max-height: 45rem) {
  .layout .scored-address-container .close-file-container {
    padding: 20px 50px 20px 50px;
    width: 980px;
  }
}
.layout .scored-address-container .close-file-container .close-file-wrapper {
  padding: 0 1.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
}
.layout .scored-address-container .close-file-container .close-file-wrapper .mdc-button {
  font-size: 0.75rem;
  border-radius: 9999px;
}
.layout .scored-address-container .close-file-btn {
  border-radius: 23.771px;
  background: var(--UPS-InsureShield-InsureShield-Teal, #00857D);
  color: var(--UPS-Brand-Colors-UPS-White, #FFF);
  font-size: 11.885px;
  font-weight: 500;
  padding: 7.428px 17.828px;
}
.layout .scored-address-container .chevron-right-icon {
  margin-left: 5px;
}
::ng-deep .scored-address-container .score-history-table-upload-address-file .score-history-layout .score-history-content {
  width: 100% !important;
  padding: 0px !important;
}
@media screen and (min-width: 75em) {
  ::ng-deep .scored-address-container .score-history-table-upload-address-file .score-history-layout .score-history-content {
    width: 1150px !important;
    padding: 0 90px 0 90px !important;
  }
}
@media screen and (min-width: 25rem) and (max-width: 80rem) and (min-height: 25rem) and (max-height: 45rem) {
  ::ng-deep .scored-address-container .score-history-table-upload-address-file .score-history-layout .score-history-content {
    width: 980px !important;
    padding: 0 50px !important;
  }
}
/*# sourceMappingURL=dd-upload-address-file.component.css.map */
