/* src/app/delivery-defense/dd-address-confidence-tool/dd-address-confidence-tool.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 21.5rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.address-confidence-tool-layout {
  width: 100%;
}
.address-confidence-tool-layout .address-confidence-tool-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  background: #FFF;
}
@media screen and (min-width: 75em) {
  .address-confidence-tool-layout .address-confidence-tool-content {
    width: 1150px;
    margin: 0 auto;
    position: relative;
    padding: 0 90px 0 90px;
  }
}
@media screen and (min-width: 25rem) and (max-width: 80rem) and (min-height: 25rem) and (max-height: 45rem) {
  .address-confidence-tool-layout .address-confidence-tool-content {
    padding: 0 50px 0 50px;
    width: 980px;
  }
}
.address-confidence-tool-layout .address-confidence-tool-content .header-panel {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 5rem;
  gap: 8px;
  padding: 0 25px 15px 25px;
}
@media screen and (min-width: 64rem) {
  .address-confidence-tool-layout .address-confidence-tool-content .header-panel {
    gap: 18px;
  }
}
.address-confidence-tool-layout .address-confidence-tool-content .header-panel img {
  max-width: 100%;
  height: auto;
}
.address-confidence-tool-layout .address-confidence-tool-content .header-panel .header {
  width: 100%;
  color: #000;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
}
@media screen and (min-width: 75rem) {
  .address-confidence-tool-layout .address-confidence-tool-content .header-panel .header {
    font-size: 26px;
  }
}
.address-confidence-tool-layout .address-confidence-tool-content .header-panel .header .divider {
  width: 72px;
  height: 4px;
  background-color: #0A8080;
  content: "";
  display: block;
}
.address-confidence-tool-layout .address-confidence-tool-content .page-description {
  width: 100%;
  padding: 0 25px 15px 25px;
  color: black;
  font-size: 16px;
  font-weight: 400;
  margin-top: 16px;
  line-height: 24px;
  display: block;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  margin-top: 50px;
  padding: 0 25px 15px 25px;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .address-search-message {
  color: black;
  font-size: 16px;
  font-weight: 400;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .required {
  color: red;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .address-search-form-container {
  margin-top: 5.97px;
  border-radius: 4px;
  border: 1px solid var(--UPSC-Gray-1, #4A4A4C);
  background: var(--ups-brand-colors-ups-grey-5, #EFEFF2);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
@media screen and (min-width: 75em) {
  .address-confidence-tool-layout .address-confidence-tool-content .address-search-container .address-search-form-container {
    flex-direction: row;
    height: 130px;
  }
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .address-search-form-container form.ng-untouched.ng-pristine.ng-invalid {
  width: 100%;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .address-search-form-container .address-search-form {
  margin: 8.96px 17px;
}
@media screen and (min-width: 75em) {
  .address-confidence-tool-layout .address-confidence-tool-content .address-search-container .address-search-form-container .address-search-form {
    display: inline-flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    width: 60vw;
  }
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .address-search-form-container .address-search-form .address-field-section {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
@media screen and (min-width: 75em) {
  .address-confidence-tool-layout .address-confidence-tool-content .address-search-container .address-search-form-container .address-search-form .address-field-section {
    flex-direction: row;
  }
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .address-search-form-container .address-search-form .address-field-section .address-field1 {
  width: 100%;
  margin-right: 10px;
}
@media screen and (min-width: 75em) {
  .address-confidence-tool-layout .address-confidence-tool-content .address-search-container .address-search-form-container .address-search-form .address-field-section .address-field1 {
    min-width: 200px;
  }
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .address-search-form-container .address-search-form .address-field-section .address-field2 {
  width: 100%;
}
@media screen and (min-width: 75em) {
  .address-confidence-tool-layout .address-confidence-tool-content .address-search-container .address-search-form-container .address-search-form .address-field-section .address-field2 {
    min-width: 140px;
    width: 200px;
  }
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .address-search-form-container .address-search-form .other-field-section {
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 75em) {
  .address-confidence-tool-layout .address-confidence-tool-content .address-search-container .address-search-form-container .address-search-form .other-field-section {
    flex-direction: row;
    gap: 10px;
  }
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .address-search-form-container .address-search-form .other-field-section .zip {
  width: 100%;
}
@media screen and (min-width: 75em) {
  .address-confidence-tool-layout .address-confidence-tool-content .address-search-container .address-search-form-container .address-search-form .other-field-section .zip {
    min-width: 103px;
  }
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .address-search-form-container .address-search-form .other-field-section .city {
  width: 100%;
}
@media screen and (min-width: 75em) {
  .address-confidence-tool-layout .address-confidence-tool-content .address-search-container .address-search-form-container .address-search-form .other-field-section .city {
    min-width: 103px;
  }
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .address-search-form-container .address-search-form .other-field-section .state {
  width: 100%;
}
@media screen and (min-width: 75em) {
  .address-confidence-tool-layout .address-confidence-tool-content .address-search-container .address-search-form-container .address-search-form .other-field-section .state {
    min-width: 103px;
  }
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .address-search-form-container .address-search-form mat-form-field {
  width: 100%;
  background-color: white;
  color: black;
  margin-bottom: 20px;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .address-search-form-container .address-search-form mat-form-field input {
  display: block;
  color: black;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-overflow: ellipsis;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .address-search-form-container .right-actions {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (min-width: 75em) {
  .address-confidence-tool-layout .address-confidence-tool-content .address-search-container .address-search-form-container .right-actions {
    flex-direction: row;
  }
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .address-search-form-container .right-actions .search-button {
  padding: 20px 42px;
  border-radius: 3px;
  border: none;
  height: 30px;
  min-width: 90px;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .address-search-form-container .right-actions .search-button span {
  font-family: "Roboto Flex", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .address-search-form-container .right-actions .divider {
  width: 0;
  height: 0;
  display: none;
}
@media screen and (min-width: 75em) {
  .address-confidence-tool-layout .address-confidence-tool-content .address-search-container .address-search-form-container .right-actions .divider {
    width: 1px;
    height: 70px;
    display: block;
    background-color: var(--UPSC-Gray-1, #4A4A4C);
    content: "";
    margin-left: 20px;
  }
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .address-search-form-container .right-actions .dd-remaining-credits {
  width: 100%;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .alert-message {
  color: black;
  font-size: 16px;
  border-left: 2.238px solid black;
  width: 100%;
  padding: 12px 9px;
  line-height: 18px;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .missing-field {
  background: #FCEAE6;
  border-left-color: #DF2901;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .recent-search-address {
  background: #D4EEDD;
  border-left-color: #29A855;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .invalid-address {
  background: #F8FBFB;
  border-left-color: #FBD300;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .no-remaining-scores {
  background: #FCEAE6;
  border-left: #DF2901;
  display: inline-flex;
  flex-direction: row;
  font-size: 14px;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .no-remaining-scores .credit-cta {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  font-weight: 400;
  color: #009cbd;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .no-remaining-scores .credit-cta.active {
  color: #009cbd;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .no-remaining-scores .credit-cta.disabled {
  color: #AFB1BF;
  pointer-events: none;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .no-remaining-scores .credit-cta:hover {
  text-decoration: none;
  color: #009cbd;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .no-remaining-scores .credit-cta .mat-icon {
  font-size: 1.3rem;
  width: 1.3rem;
  height: 1.3rem;
  margin-right: 0.25rem;
  margin-left: 0.25rem;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .no-remaining-scores .credit-cta span {
  text-decoration: underline;
  margin-right: 0.25rem;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .tips-section .tips-message {
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-top: 5px;
  font-weight: normal;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .tips-section .tips-message a {
  color: #0662BB;
  cursor: pointer;
  text-decoration: underline;
  -webkit-user-select: none;
  user-select: none;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .tips-section .tips-panel {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .tips-section .tips-panel .close-container {
  display: inline-flex;
  width: 100%;
  justify-content: flex-end;
  align-items: flex-start;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .tips-section .tips-panel .close-container .close-btn {
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  cursor: pointer;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .tips-section .tips-panel .tips-container {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .tips-section .tips-panel .tips-container .tips-top-section,
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .tips-section .tips-panel .tips-container .tips-bottom-section {
  display: inline-flex;
  align-items: center;
  gap: 25px;
  flex-direction: column;
}
@media screen and (min-width: 75rem) {
  .address-confidence-tool-layout .address-confidence-tool-content .address-search-container .tips-section .tips-panel .tips-container .tips-top-section,
  .address-confidence-tool-layout .address-confidence-tool-content .address-search-container .tips-section .tips-panel .tips-container .tips-bottom-section {
    flex-direction: row;
  }
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .tips-section .tips-panel .tips-container .tips-top-section .tip,
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .tips-section .tips-panel .tips-container .tips-bottom-section .tip {
  display: flex;
  align-items: flex-start;
  gap: 1.5px;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .tips-section .tips-panel .tips-container .tips-top-section .tip h1,
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .tips-section .tips-panel .tips-container .tips-bottom-section .tip h1 {
  color: #00857D;
  text-align: center;
  font-family: Inter, serif;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .tips-section .tips-panel .tips-container .tips-top-section .tip .sub-section,
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .tips-section .tips-panel .tips-container .tips-bottom-section .tip .sub-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .tips-section .tips-panel .tips-container .tips-top-section .tip .sub-section h2,
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .tips-section .tips-panel .tips-container .tips-bottom-section .tip .sub-section h2 {
  color: var(--ups-brand-colors-ups-black, #121212);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .tips-section .tips-panel .tips-container .tips-top-section .tip .sub-section .lightbulb,
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .tips-section .tips-panel .tips-container .tips-top-section .tip .sub-section .period,
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .tips-section .tips-panel .tips-container .tips-bottom-section .tip .sub-section .lightbulb,
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .tips-section .tips-panel .tips-container .tips-bottom-section .tip .sub-section .period {
  width: 56px;
  height: 56px;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .tips-section .tips-panel .tips-container .tips-top-section .tip .sub-section .comma,
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .tips-section .tips-panel .tips-container .tips-bottom-section .tip .sub-section .comma {
  position: initial;
  width: 14px;
  height: 16.5px;
  margin-top: 20.5px;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .tips-section .tips-panel .tips-container .tips-top-section .tip .sub-section .blocked,
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .tips-section .tips-panel .tips-container .tips-bottom-section .tip .sub-section .blocked {
  position: initial;
  width: 56px;
  height: 56px;
  margin-top: -35px;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .tips-section .tips-panel .tips-container .tips-top-section .divider,
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .tips-section .tips-panel .tips-container .tips-bottom-section .divider {
  display: none;
}
@media screen and (min-width: 75rem) {
  .address-confidence-tool-layout .address-confidence-tool-content .address-search-container .tips-section .tips-panel .tips-container .tips-top-section .divider,
  .address-confidence-tool-layout .address-confidence-tool-content .address-search-container .tips-section .tips-panel .tips-container .tips-bottom-section .divider {
    display: block;
    width: 2.5px;
    height: 100px;
    background: #D8D7DF;
    content: "";
  }
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .score-container {
  width: 100%;
  margin-top: 75px;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .score-container .score-information-wrapper {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .score-container .score-information-wrapper .label {
  color: black;
  font-size: 12px;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-style: normal;
}
@media screen and (min-width: 75rem) {
  .address-confidence-tool-layout .address-confidence-tool-content .address-search-container .score-container .score-information-wrapper .label {
    font-size: 16px;
  }
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .score-container .score-information-wrapper .label::after {
  content: "";
}
@media screen and (min-width: 75rem) {
  .address-confidence-tool-layout .address-confidence-tool-content .address-search-container .score-container .score-information-wrapper .label::after {
    content: " Confidence";
  }
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .score-container .score-information-wrapper .score-text {
  font-size: 20px;
  margin-bottom: 20px;
  color: black;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-style: normal;
  -webkit-user-select: none;
  user-select: none;
}
@media screen and (min-width: 75rem) {
  .address-confidence-tool-layout .address-confidence-tool-content .address-search-container .score-container .score-information-wrapper .score-text {
    font-size: 30px;
  }
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .score-container .score-information-wrapper .score-text .question-icon {
  position: relative;
  bottom: 15px;
  right: 5px;
  font-size: 8px;
  cursor: pointer;
}
@media screen and (min-width: 75rem) {
  .address-confidence-tool-layout .address-confidence-tool-content .address-search-container .score-container .score-information-wrapper .score-text .question-icon {
    font-size: 12px;
  }
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .score-container .score-gradient-bar-container {
  height: 40px;
  width: 100%;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .score-container .score-gradient-bar-container .score-gradient-bar {
  background:
    linear-gradient(
      90deg,
      #F66E6E 0%,
      #F6AC68 25%,
      #F1D367 49.48%,
      #E1F46D 72.4%,
      #97F26C 100%);
  height: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px -3px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(0, 0, 0, 0.14);
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .score-container .score-gradient-bar-container .score-gradient-bar .score-slider {
  -webkit-appearance: none;
  width: 100%;
  background: none;
  outline: none;
  pointer-events: none;
  position: relative;
  bottom: 10px;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .score-container .score-gradient-bar-container .score-gradient-bar .score-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 40px;
  border-radius: 7.5px;
  border: 1.5px solid #757583;
  background: #4A4A4C;
  box-shadow: 0 4px 12px -3px rgba(0, 0, 0, 0.05), 0 2px 5px 0 rgba(0, 0, 0, 0.14);
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .score-container .score-range-labels {
  width: 100%;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .score-container .score-range-labels .span {
  color: black;
  font-size: 16px;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Roboto Flex", sans-serif;
  font-style: normal;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .score-container .scores {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  flex-direction: column-reverse;
  gap: 1px;
  border-bottom: 0.658px solid #D9D9D9;
  flex: 1;
  width: 100%;
}
@media screen and (min-width: 75rem) {
  .address-confidence-tool-layout .address-confidence-tool-content .address-search-container .score-container .scores {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
  }
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .score-container .scores .score {
  flex: 1;
}
@media screen and (min-width: 75rem) {
  .address-confidence-tool-layout .address-confidence-tool-content .address-search-container .score-container .scores .score:last-of-type {
    flex: 0.5454545455;
  }
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .score-container .scores .score .score-range {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #4A4A4C;
  color: white;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  line-height: 2.25;
  height: 2.30263rem;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .score-container .scores .score ::ng-deep .score-message {
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.15131rem;
  text-align: center;
  padding: 1rem 0.5rem;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .score-container .scores .score ::ng-deep .score-message .multiplier,
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .score-container .scores .score ::ng-deep .score-message .range {
  display: block;
}
.address-confidence-tool-layout .address-confidence-tool-content .address-search-container .score-container .scores .score ::ng-deep .score-message .multiplier {
  font-size: 1rem;
  font-weight: 700;
}
::ng-deep .dd-remaining-credits .credit-row .credit-column .credit-description {
  margin-left: 0;
}
::ng-deep .dd-remaining-credits {
  margin-top: 20px;
}
@media screen and (min-width: 75em) {
  ::ng-deep .dd-remaining-credits {
    margin-top: 0;
  }
}
::ng-deep .address-confidence-tool-layout .score-history-table-address-confidence-tool .score-history-layout .score-history-content {
  width: 100% !important;
  padding: 0px !important;
}
@media screen and (min-width: 75em) {
  ::ng-deep .address-confidence-tool-layout .score-history-table-address-confidence-tool .score-history-layout .score-history-content {
    width: 1150px !important;
    padding: 0 90px 0 90px !important;
  }
}
@media screen and (min-width: 25rem) and (max-width: 80rem) and (min-height: 25rem) and (max-height: 45rem) {
  ::ng-deep .address-confidence-tool-layout .score-history-table-address-confidence-tool .score-history-layout .score-history-content {
    width: 980px !important;
    padding: 0 50px !important;
  }
}
/*# sourceMappingURL=dd-address-confidence-tool.component.css.map */
