/* src/app/sign-up/business-information/business-information.component.scss */
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.fill-height,
.white-content-page,
.white-sheet-content {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  align-content: stretch;
}
.page-center,
.white-content-page .ups-content-block,
.white-content-page .ups-page-header,
.white-sheet-content {
  min-width: 21.5rem;
  max-width: 71.875rem;
  width: 100%;
  margin: 0 auto;
}
.white-sheet-content {
  background-color: white;
}
.white-sheet-content .ups-page-header {
  padding: 1.125rem 23px;
}
@media screen and (min-width: 48rem) {
  .white-sheet-content .ups-page-header {
    padding: 2.625rem 2rem;
  }
}
.white-content-page {
  background-color: white;
  min-height: 100%;
}
.white-content-page .ups-page-header {
  padding-bottom: 1em;
  min-width: auto;
}
.white-content-page .ups-content-block {
  padding: 0 2rem 2em;
}
.business-information {
  margin-bottom: 10px;
}
.business-information .red-asterik {
  color: #d50000;
}
.business-information .company-info {
  margin: 1em 1em;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 48rem) {
  .business-information .company-info {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 48rem) {
  .business-information .company-info .mat-mdc-form-field {
    width: 49%;
    padding: 0em 1em;
  }
}
.business-information .company-info .mat-form-field-hint-wrapper .mat-mdc-form-field-hint {
  color: #9b9b9b;
  font-size: 14px;
}
.business-information .company-info .business-location {
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 48rem) {
  .business-information .company-info .business-location {
    width: 49%;
  }
  .business-information .company-info .business-location > label {
    padding-left: 1em;
  }
}
.business-information .company-info .business-location > label {
  font-size: 13px;
  letter-spacing: 0.46px;
  color: #4a4a4a;
  font-weight: 600;
  line-height: 2.5;
  padding-left: 2rem;
}
.business-information .company-info .business-location .mat-mdc-form-field {
  width: 100%;
}
.business-information .company-info .which-one {
  width: 100%;
  justify-content: flex-end;
  display: flex;
  margin-right: 1.5em;
}
.business-information .company-info .which-one .mat-mdc-form-field {
  width: 100%;
}
@media screen and (min-width: 48rem) {
  .business-information .company-info .which-one .mat-mdc-form-field {
    width: 50%;
  }
}
.business-information .business-scale {
  margin: 1em 1em;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 48rem) {
  .business-information .business-scale {
    flex-direction: row;
    margin: 1em 3.5em 1em 2em;
    justify-content: space-between;
  }
}
@media only screen and (min-width: 1650px) {
  .business-information .business-scale {
    margin: 1em 4em 1em 2em;
  }
}
.business-information .business-scale .business-details {
  display: flex;
  flex-direction: row;
  color: #4a4a4a;
  align-items: baseline;
}
@media screen and (min-width: 48rem) {
  .business-information .business-scale .business-details {
    width: 23%;
  }
}
.business-information .business-scale .business-details .key {
  width: 50%;
  font-size: 14px;
  font-weight: 600;
}
@media only screen and (max-width: 21.5rem) {
  .business-information .business-scale .business-details .key {
    margin-right: 1em;
  }
}
.business-information .business-scale .business-details .value {
  width: 50%;
  margin-top: -0.5em;
}
.business-information .business-scale .business-details .value .mat-mdc-form-field {
  display: block;
}
@media screen and (min-width: 48rem) {
  .business-information .business-scale .business-details .years-in-business {
    width: 70%;
  }
}
.business-information .business-type {
  color: #4a4a4a;
  margin: 1.5em 2em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.business-information .business-type .types {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 200px;
  font-size: 14px;
}
.business-information .business-type .types .mat-mdc-form-field {
  margin: 0.5em 2em;
  border-radius: 3px;
}
.business-information .business-type .types .mat-mdc-form-field .mat-form-field-underline {
  height: 0;
}
.business-information .business-type .types label {
  color: #4a4a4a;
  letter-spacing: 0.53px;
}
.business-information .business-type .types .mat-icon {
  font-size: 13px;
  height: 16px;
  width: 13px;
  color: #817b7b;
  margin-left: 0.2em;
}
.business-information .business-type .types .mat-mdc-radio-group {
  display: flex;
  flex-direction: column;
}
.business-information .business-type .movement-types-and-merchandise-condition-container {
  width: 100%;
  color: #5F5753;
}
.business-information .business-type .movement-types-and-merchandise-condition-container .merchandise-condition {
  margin-top: 22px;
}
.business-information .business-type .movement-types-and-merchandise-condition-container .first {
  margin-top: 8px;
}
.business-information .business-type .movement-types-and-merchandise-condition-container .percent-container {
  display: block;
}
@media screen and (min-width: 48rem) {
  .business-information .business-type .movement-types-and-merchandise-condition-container .percent-container {
    display: flex;
    height: 48px;
  }
}
.business-information .business-type .movement-types-and-merchandise-condition-container .percent-container span {
  min-width: 150px;
  height: 24px;
}
.business-information .business-type .movement-types-and-merchandise-condition-container .percent-container .percent-wrapper {
  width: 100%;
  display: flex;
  height: 59px;
  margin-left: 6px;
}
@media screen and (min-width: 48rem) {
  .business-information .business-type .movement-types-and-merchandise-condition-container .percent-container .percent-wrapper {
    width: 20%;
    margin-top: -5px;
  }
}
/*# sourceMappingURL=business-information.component.css.map */
